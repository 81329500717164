import {
  InputAdornment,
  TextField,
  Typography,
  Box,
  Button,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {
  formatNumber,
  NumberFormatType,
  UserProfileAccountGroup,
  UserProfileFinancialAccountNickname,
} from '@newedge/common';
import { useNavigate, useParams } from 'react-router-dom';
import graphql from 'babel-plugin-relay/macro';
import {
  PreloadedQuery,
  usePreloadedQuery,
  UseQueryLoaderLoadQueryOptions,
} from 'react-relay';
import { UserAccessConfigurationViewQuery } from './__generated__/UserAccessConfigurationViewQuery.graphql';
import combineUserProfileAndClientSummaryFinancialAccountInfo from '../../models/InternalPortalFinancialAccount';
import { InternalFinancialAccountInfo } from '../../@types/global';
import _ from 'lodash';
import AccountPortalTableComponent from './AccountPortalTableComponent';
import GoBackLinkButton from '../../components/buttons/GoBackLinkButton';
import { ClientSummaryInfo } from '../../components/summary/ClientSummaryInfo';

export const UserAccessConfigurationViewQueryNode = graphql`
  query UserAccessConfigurationViewQuery(
    $getClientUserInput: GetClientUserInput!
  ) {
    clientsummary_viewer {
      client {
        userId
        accounts {
          accountDefaultName
          accountId
          accountNumber
          accountType
          managementStyle
          totalBalance
        }
        summary {
          netAssets
        }
      }
    }
    userprofile_viewer {
      displayName
      clientUser(input: $getClientUserInput) {
        id
        displayName
        accounts {
          accountNickname
          financialAccountId
        }
        accountGroups {
          accounts {
            financialAccountId
            id
          }
          id
          name
        }
      }
    }
  }
`;

interface UserAccessConfigurationWrapperProps {
  routeQueryRef: PreloadedQuery<UserAccessConfigurationViewQuery>;
  loadRouteQuery: (
    variables: { [key: string]: any },
    options?: UseQueryLoaderLoadQueryOptions
  ) => void;
}

export interface AutoCompleteOption {
  label: string;
  id: string;
}

const UserAccessConfigurationView = ({
  routeQueryRef,
  loadRouteQuery,
}: UserAccessConfigurationWrapperProps) => {
  const [searchValue, setSearchValue] = useState('');
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const data = usePreloadedQuery(
    UserAccessConfigurationViewQueryNode,
    routeQueryRef
  );

  const navigate = useNavigate();

  const { clientUserId: clientId = '' } = useParams();

  const keysToIgnore = ['financialAccountId'];

  const clientUserProfile = data.userprofile_viewer?.clientUser.find(
    (x) => x.id === clientId
  );

  const clientSummary = data.clientsummary_viewer?.client.find(
    (x) => x.userId === clientId
  );

  const clientAccountNicknames: UserProfileFinancialAccountNickname[] =
    clientUserProfile?.accounts as UserProfileFinancialAccountNickname[];

  const clientAccountGroups: UserProfileAccountGroup[] =
    clientUserProfile?.accountGroups as UserProfileAccountGroup[];

  const summaryAccounts: InternalFinancialAccountInfo[] =
    clientSummary?.accounts as InternalFinancialAccountInfo[];

  const handlerRefreshConfigurationView = () => {
    if (!loadRouteQuery) return;
    loadRouteQuery(
      {
        getClientUserInput: {
          clientUserIds: [clientId],
        },
      },
      { fetchPolicy: 'network-only' }
    );
  };

  const userAccessConfigurationData =
    combineUserProfileAndClientSummaryFinancialAccountInfo(
      clientAccountNicknames,
      clientAccountGroups,
      summaryAccounts
    );

  const userAccessConfigurationRows = useMemo(
    () =>
      userAccessConfigurationData !== undefined
        ? userAccessConfigurationData
            .filter((userAccessConfiguration) => {
              //if searchValue is blank ignore
              if (searchValue && searchValue !== '') {
                //Convert all data props to values to search thru
                return Object.entries(userAccessConfiguration).some(
                  (value: any) =>
                    !keysToIgnore.includes(value[0]) &&
                    typeof value[1] !== 'object' &&
                    value[1]
                      ?.toString()
                      .toLowerCase()
                      .indexOf(searchValue.toLowerCase()) > -1
                );
              }
              return true;
            })
        : [],
    [userAccessConfigurationData, searchValue]
  );

  return (
    <>
      <Box sx={{ padding: 1 }}>
        <GoBackLinkButton link='/my/clients' label='View all clients' />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ClientSummaryInfo
          displayName={
            clientUserProfile?.displayName ? clientUserProfile?.displayName : ''
          }
          netAssets={clientSummary?.summary?.netAssets}
        />
        <Button
          onClick={() => {
            navigate(`/my/client-profile-management/${clientId}`);
          }}
          sx={{ ml: 8, height: '42px' }}
          data-testid='EditClientInfoButton'
        >
          Edit Client Info
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 2,
        }}
      >
        <TextField
          type='search'
          variant='outlined'
          color='secondary'
          size='small'
          onChange={handleSearch}
          value={searchValue}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={(theme) => ({
            margin: 1,
            border: 1,
            borderColor: theme.extensions.grey[28],
            borderRadius: 1,
          })}
        />
        <Button
          onClick={() => {
            navigate(`/my/account-group-edit/${clientId}`);
          }}
        >
          Edit Account Groups
        </Button>
      </Box>
      <AccountPortalTableComponent
        data={userAccessConfigurationRows}
        clientId={clientId}
        handlerRefreshConfigurationView={handlerRefreshConfigurationView}
      />
    </>
  );
};

export default UserAccessConfigurationView;
