/**
 * @generated SignedSource<<0bbe5d4a8056af1a074f36912d7c2ad4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GetClientUserInput = {
  clientUserIds: ReadonlyArray<any>;
};
export type DashboardPageQuery$variables = {
  input: GetClientUserInput;
};
export type DashboardPageQuery$data = {
  readonly clientsummary_viewer: {
    readonly client: ReadonlyArray<{
      readonly summary: {
        readonly netAssets: any;
      } | null;
      readonly userId: any;
    }>;
  };
  readonly userprofile_viewer: {
    readonly clientUser: ReadonlyArray<{
      readonly displayName: string;
      readonly firstName: string;
      readonly id: any;
      readonly lastName: string;
    }>;
    readonly displayName: string;
  } | null;
};
export type DashboardPageQuery = {
  response: DashboardPageQuery$data;
  variables: DashboardPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "ClientModel",
  "kind": "LinkedField",
  "name": "client",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientSummaryModel",
      "kind": "LinkedField",
      "name": "summary",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "netAssets",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "input",
      "variableName": "input"
    }
  ],
  "concreteType": "ClientUserViewModel",
  "kind": "LinkedField",
  "name": "clientUser",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientSummaryViewer",
        "kind": "LinkedField",
        "name": "clientsummary_viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserProfileViewer",
        "kind": "LinkedField",
        "name": "userprofile_viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DashboardPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientSummaryViewer",
        "kind": "LinkedField",
        "name": "clientsummary_viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserProfileViewer",
        "kind": "LinkedField",
        "name": "userprofile_viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3be3f48a6bb5cf1243ed446335ceee09",
    "id": null,
    "metadata": {},
    "name": "DashboardPageQuery",
    "operationKind": "query",
    "text": "query DashboardPageQuery(\n  $input: GetClientUserInput!\n) {\n  clientsummary_viewer {\n    client {\n      userId\n      summary {\n        netAssets\n      }\n    }\n    id\n  }\n  userprofile_viewer {\n    displayName\n    clientUser(input: $input) {\n      displayName\n      firstName\n      lastName\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9033613ce4c61add85589e1b6ea18285";

export default node;
