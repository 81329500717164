import { useQueryLoader } from 'react-relay';
import {
  PerformancePreviewQueryReader,
  PerformancePreviewQueryReaderBenchmarkQueryNode,
  PerformancePreviewQueryReaderQueryNode,
} from './PerformancePreviewQueryReader';
import { useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { GlobalFilterContext } from '../../../components/context/GlobalFilterContext';
import { PerformancePreviewQueryReaderQuery } from './__generated__/PerformancePreviewQueryReaderQuery.graphql';
import { PerformancePreviewQueryReaderBenchmarkQuery } from './__generated__/PerformancePreviewQueryReaderBenchmarkQuery.graphql';

export const PerformancePreviewLoader = () => {
  const [performanceQueryRef, loadPerformanceQuery] =
    useQueryLoader<PerformancePreviewQueryReaderQuery>(
      PerformancePreviewQueryReaderQueryNode
    );

  const [benchmarkQueryRef, loadBenchmarkQuery] =
    useQueryLoader<PerformancePreviewQueryReaderBenchmarkQuery>(
      PerformancePreviewQueryReaderBenchmarkQueryNode
    );

  const { allAccounts } = useContext(GlobalFilterContext);
  const { clientUserId } = useParams();

  const variables = useMemo(
    () => ({
      input: {
        clientUserIds: [clientUserId],
      },
      clientWhere: {
        userId: { eq: clientUserId },
      },
      performanceInput: {
        financialAccountIds: allAccounts,
      },
    }),
    [clientUserId, allAccounts]
  );

  useEffect(() => {
    loadPerformanceQuery(variables);
  }, [loadPerformanceQuery, variables]);

  useEffect(() => {
    loadBenchmarkQuery({});
  }, [loadBenchmarkQuery]);

  if (performanceQueryRef && benchmarkQueryRef) {
    return (
      <PerformancePreviewQueryReader
        performanceQueryRef={performanceQueryRef}
        benchmarkQueryRef={benchmarkQueryRef}
      />
    );
  }
  return <></>;
};
