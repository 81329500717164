/**
 * @generated SignedSource<<3f3ed4c9f06b7cafdb7109c88c9450ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RequestStatus = "FAILED" | "PROCESSED" | "PROCESSING" | "%future added value";
export type UpdateClientAccountNicknameInput = {
  accountNickname: string;
  clientId: any;
  financialAccountId: number;
};
export type ClientAccountNicknameConfigureMutation$variables = {
  updateClientAccountNicknameInput: UpdateClientAccountNicknameInput;
};
export type ClientAccountNicknameConfigureMutation$data = {
  readonly UpdateClientAccountNickname: {
    readonly errors: ReadonlyArray<{
      readonly code: string;
      readonly message: string;
    }> | null;
    readonly partitionKey: string;
    readonly request: {
      readonly correlationId: any;
      readonly status: RequestStatus;
    };
    readonly userState: {
      readonly accountGroups: ReadonlyArray<{
        readonly accounts: ReadonlyArray<{
          readonly financialAccountId: number;
          readonly id: any;
        }>;
        readonly id: string;
        readonly name: string;
      }>;
      readonly accounts: ReadonlyArray<{
        readonly accountNickname: string;
        readonly financialAccountId: number;
      }>;
      readonly displayName: string;
    } | null;
    readonly viewer: {
      readonly accountGroups: ReadonlyArray<{
        readonly accounts: ReadonlyArray<{
          readonly financialAccountId: number;
          readonly id: any;
        }>;
        readonly id: string;
        readonly name: string;
      }>;
      readonly accounts: ReadonlyArray<{
        readonly accountNickname: string;
        readonly financialAccountId: number;
      }>;
      readonly displayName: string;
    } | null;
  };
};
export type ClientAccountNicknameConfigureMutation = {
  response: ClientAccountNicknameConfigureMutation$data;
  variables: ClientAccountNicknameConfigureMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "updateClientAccountNicknameInput"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "updateClientAccountNicknameInput"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "partitionKey",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Request",
  "kind": "LinkedField",
  "name": "request",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "correlationId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "financialAccountId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "UserAccount",
  "kind": "LinkedField",
  "name": "accounts",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountNickname",
      "storageKey": null
    },
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "AccountGroup",
  "kind": "LinkedField",
  "name": "accountGroups",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": true,
      "selections": [
        (v7/*: any*/),
        (v9/*: any*/)
      ],
      "storageKey": null
    },
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = [
  (v6/*: any*/),
  (v8/*: any*/),
  (v10/*: any*/)
],
v12 = [
  (v6/*: any*/),
  (v8/*: any*/),
  (v10/*: any*/),
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientAccountNicknameConfigureMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserPayload",
        "kind": "LinkedField",
        "name": "UpdateClientAccountNickname",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfileViewer",
            "kind": "LinkedField",
            "name": "userState",
            "plural": false,
            "selections": (v11/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfileViewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": (v11/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientAccountNicknameConfigureMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserPayload",
        "kind": "LinkedField",
        "name": "UpdateClientAccountNickname",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfileViewer",
            "kind": "LinkedField",
            "name": "userState",
            "plural": false,
            "selections": (v12/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfileViewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": (v12/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a8edcf305ccd29a3e6db9023f01665eb",
    "id": null,
    "metadata": {},
    "name": "ClientAccountNicknameConfigureMutation",
    "operationKind": "mutation",
    "text": "mutation ClientAccountNicknameConfigureMutation(\n  $updateClientAccountNicknameInput: UpdateClientAccountNicknameInput!\n) {\n  UpdateClientAccountNickname(input: $updateClientAccountNicknameInput) {\n    errors {\n      __typename\n      code\n      message\n    }\n    partitionKey\n    request {\n      correlationId\n      status\n    }\n    userState {\n      displayName\n      accounts {\n        accountNickname\n        financialAccountId\n      }\n      accountGroups {\n        accounts {\n          financialAccountId\n          id\n        }\n        id\n        name\n      }\n      id\n    }\n    viewer {\n      displayName\n      accounts {\n        accountNickname\n        financialAccountId\n      }\n      accountGroups {\n        accounts {\n          financialAccountId\n          id\n        }\n        id\n        name\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2a7159c2e0f760dcfc52110d580644ab";

export default node;
