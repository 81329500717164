import { useQueryLoader } from "react-relay";
import { AssetAllocationPreviewQueryReader, AssetAllocationPreviewQueryReaderQueryNode } from "./AssetAllocationPreviewQueryReader";
import { useContext, useEffect, useMemo, useState } from "react";
import { FilterItem } from "@newedge/common";
import { GlobalFilterContext } from "../../../components/context/GlobalFilterContext";
import { useParams } from "react-router";
import { AssetAllocationPreviewQueryReaderQuery, AssetAllocationPreviewQueryReaderQuery$variables } from "./__generated__/AssetAllocationPreviewQueryReaderQuery.graphql";

const applySecondaryFilters = (
  variables: AssetAllocationPreviewQueryReaderQuery$variables,
  filterValues: FilterItem[]
): AssetAllocationPreviewQueryReaderQuery$variables => {
  const newVariables = Object.assign({}, variables);
  let input = newVariables.assetAllocationInput;
  const accountIdFilters = filterValues.filter(
    (o) => o.predicateIdentifier === 'accountId'
  );
  if (accountIdFilters.length > 0) {
    const ids: any = new Set();
    accountIdFilters.forEach((o) => {
      if (o.searchValue instanceof Array) {
        o.searchValue.forEach((val) => {
          if (input.financialAccountIds.includes(Number(val))) {
            ids.add(val);
          }
        });
      } else {
        if (input.financialAccountIds.includes(Number(o.searchValue))) {
          ids.add(o.searchValue);
        }
      }
    });
    newVariables.assetAllocationInput = {
      financialAccountIds: [...ids],
    };
  }
  return newVariables;
};

export const AssetAllocationPreviewLoader = () => {
  const [queryRef, loadQuery] = useQueryLoader<AssetAllocationPreviewQueryReaderQuery>(
    AssetAllocationPreviewQueryReaderQueryNode
  );

  const [filterValues, setFilterValues] = useState<FilterItem[]>([]);
  const { selectedAccounts } = useContext(GlobalFilterContext);
  const { clientUserId } = useParams();

  const variables = useMemo(() => ({
    input: {
      clientUserIds: [clientUserId],
    },
    clientWhere: {
      userId: { eq: clientUserId },
    },
    assetAllocationInput: {
      financialAccountIds: selectedAccounts,
    },
  }), [clientUserId, selectedAccounts]);

  useEffect(() => {
    loadQuery(applySecondaryFilters(variables, filterValues));
  }, [loadQuery, variables, filterValues]);

  if (queryRef) {
    return (
      <AssetAllocationPreviewQueryReader
        queryRef={queryRef}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
      />
    );
  }
  return <></>;
}