/**
 * @generated SignedSource<<71ad9fe4fcd102b590e6fcb5c8dd1590>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RequestStatus = "FAILED" | "PROCESSED" | "PROCESSING" | "%future added value";
export type UpdateClientUserInput = {
  clientId: any;
  displayName: string;
};
export type EditClientInfoConfigurationView_ManageDisplayNameRowMutation$variables = {
  input: UpdateClientUserInput;
};
export type EditClientInfoConfigurationView_ManageDisplayNameRowMutation$data = {
  readonly UpdateClientUser: {
    readonly errors: ReadonlyArray<{
      readonly code: string;
      readonly message: string;
    }> | null;
    readonly partitionKey: string;
    readonly request: {
      readonly correlationId: any;
      readonly status: RequestStatus;
    };
    readonly userState: {
      readonly displayName: string;
    } | null;
    readonly viewer: {
      readonly displayName: string;
    } | null;
  };
};
export type EditClientInfoConfigurationView_ManageDisplayNameRowMutation = {
  response: EditClientInfoConfigurationView_ManageDisplayNameRowMutation$data;
  variables: EditClientInfoConfigurationView_ManageDisplayNameRowMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "partitionKey",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Request",
  "kind": "LinkedField",
  "name": "request",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "correlationId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v7 = [
  (v6/*: any*/)
],
v8 = [
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditClientInfoConfigurationView_ManageDisplayNameRowMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserPayload",
        "kind": "LinkedField",
        "name": "UpdateClientUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfileViewer",
            "kind": "LinkedField",
            "name": "userState",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfileViewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditClientInfoConfigurationView_ManageDisplayNameRowMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserPayload",
        "kind": "LinkedField",
        "name": "UpdateClientUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfileViewer",
            "kind": "LinkedField",
            "name": "userState",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfileViewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5929d983e08a4e19edb5f29db70eafd2",
    "id": null,
    "metadata": {},
    "name": "EditClientInfoConfigurationView_ManageDisplayNameRowMutation",
    "operationKind": "mutation",
    "text": "mutation EditClientInfoConfigurationView_ManageDisplayNameRowMutation(\n  $input: UpdateClientUserInput!\n) {\n  UpdateClientUser(input: $input) {\n    errors {\n      __typename\n      code\n      message\n    }\n    partitionKey\n    request {\n      correlationId\n      status\n    }\n    userState {\n      displayName\n      id\n    }\n    viewer {\n      displayName\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "62be2e6af97b66a96c442d604d8fd097";

export default node;
