import { Box, ClickAwayListener, Popper } from '@mui/material';
import Paper from '@mui/material/Paper';
import { SxProps, styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { tableCellClasses } from '@mui/material';
import moment from 'moment';
import React, { Key, ReactNode, useCallback, useMemo } from 'react';
import { formatNumber, NumberFormatType } from '@newedge/common';
import NotAvailableTypography from '../typography/NotAvailableTypography';
import { ColumnDataType, DataAlignment, HeadCell, Order } from './@types';
import { getDefaultComparator, stableSort } from './SortHelpers';

interface SortableTableHeadProps<T> {
  onRequestSort: (property: keyof T) => void;
  order: Order;
  orderBy: string | number | symbol;
  headCells: HeadCell<T>[];
  setOrder: (order: Order) => void;
  setOrderBy: (orderBy: keyof T) => void;
  actionRenderer?: (row: T) => ReactNode;
}

const SortableTableHead = <T extends unknown>(
  props: SortableTableHeadProps<T>
) => {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
    setOrder,
    setOrderBy,
    actionRenderer,
  } = props;
  const createSortHandler = (property: keyof T) => () => {
    onRequestSort(property);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: 0,
    verticalAlign: 'middle',
  }));

  const renderCustomSort = (
    customSortRenderer: HeadCell<T>['customSortRenderer']
  ) => {
    return (
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          {
            customSortRenderer?.(setOrder, setOrderBy, () =>
              setAnchorEl(null)
            ) as any
          }
        </ClickAwayListener>
      </Popper>
    );
  };

  const isActive = (headCell: HeadCell<T>) => {
    return (
      orderBy === headCell.id ||
      (headCell.relatedIds && headCell.relatedIds.includes(orderBy as keyof T))
    );
  };

  const hiddenText =
    order === Order.Desc ? 'sorted descending' : 'sorted ascending';

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: HeadCell<T>) => {
          return (
            <StyledTableCell
              key={headCell.id as Key}
              align='left'
              sortDirection={isActive(headCell) ? order : false}
            >
              <TableSortLabel
                active={isActive(headCell)}
                direction={isActive(headCell) ? order : Order.Asc}
                onClick={
                  headCell.customSortRenderer
                    ? handleClick
                    : createSortHandler(headCell.id)
                }
                IconComponent={ArrowDropDownIcon}
                sx={(theme) => ({
                  ...theme.typography.body5,
                  alignItems: 'start',
                })}
                hideSortIcon={!!headCell.customSortRenderer}
              >
                {headCell.label}
                {isActive(headCell) ? (
                  <Box
                    component='span'
                    sx={{
                      border: 0,
                      clip: 'rect(0 0 0 0)',
                      height: 1,
                      margin: -1,
                      overflow: 'hidden',
                      padding: 0,
                      position: 'absolute',
                      top: 20,
                      width: 1,
                    }}
                  >
                    {hiddenText}
                  </Box>
                ) : null}
              </TableSortLabel>
              {headCell.customSortRenderer
                ? renderCustomSort(headCell.customSortRenderer)
                : null}
            </StyledTableCell>
          );
        })}
        {actionRenderer ? <StyledTableCell /> : null}
      </TableRow>
    </TableHead>
  );
};

//Set up all the themed colors and  typography classes

interface SortableTableProps<T> {
  headCells: HeadCell<T>[];
  dataRows: T[];
  initialSortColumn: keyof T;
  initialSortDirection?: Order;
  keyFields?: (keyof T)[];
  sx?: SxProps;
  actionRenderer?: (row: T) => ReactNode;
}

export const SortableTable = <T extends unknown>({
  headCells,
  dataRows,
  initialSortColumn,
  initialSortDirection = Order.Asc,
  keyFields = [],
  sx = {},
  actionRenderer,
}: SortableTableProps<T>) => {
  const [order, setOrder] = React.useState<Order>(initialSortDirection);
  const [orderBy, setOrderBy] = React.useState<keyof T>(initialSortColumn);

  const handleRequestSort = useCallback(
    (property: keyof T) => {
      const isAsc = orderBy === property && order === Order.Asc;
      setOrder(isAsc ? Order.Desc : Order.Asc);
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const getFormattedText = useCallback(
    (value: number | string, headCell: HeadCell<T>): string | null => {
      if (headCell.transformFn) {
        return headCell.transformFn(value as number);
      }

      if (!value && headCell.nullDisplay) {
        return headCell.nullDisplay;
      }

      if (value === null || value === undefined) {
        return null;
      }

      switch (headCell.dataType) {
        case ColumnDataType.Date:
          return moment(value).format('M/D/YYYY');
        case ColumnDataType.Currency:
          return formatNumber(
            value as number,
            NumberFormatType.Currency,
            headCell.precision ?? 2
          );
        case ColumnDataType.Percent:
          return formatNumber(
            value as number,
            NumberFormatType.Percent,
            headCell.precision,
            headCell.minimumDecimalToDisplay
          );
        case ColumnDataType.Number:
          return formatNumber(
            value as number,
            NumberFormatType.Number,
            headCell.precision,
            headCell.minimumDecimalToDisplay
          );
      }
      return value as string;
    },
    []
  );

  const format = useCallback(
    (value: number | string, headCell: HeadCell<T>) => {
      let formattedText = getFormattedText(value, headCell);

      if (
        headCell.maxLength &&
        formattedText &&
        formattedText.length > headCell.maxLength
      ) {
        return (
          <Tooltip
            title={<Box sx={{ fontSize: '1.4rem' }}>{formattedText}</Box>}
          >
            <Typography>
              {`${formattedText.substring(0, headCell.maxLength)}...`}
            </Typography>
          </Tooltip>
        );
      }

      return <NotAvailableTypography>{formattedText}</NotAvailableTypography>;
    },
    [getFormattedText]
  );

  const getSortComparator = useCallback(() => {
    const sortHeadCell = headCells.find(
      (headCell) =>
        headCell.id === orderBy ||
        (headCell.relatedIds && headCell.relatedIds?.some((x) => x === orderBy))
    );

    if (sortHeadCell?.customSortComparator) {
      return sortHeadCell.customSortComparator(order, orderBy);
    }
    return getDefaultComparator(order, orderBy);
  }, [headCells, order, orderBy]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: 0,
    padding: 14,
    verticalAlign: 'middle',
    [`&.${tableCellClasses.head}`]: {
      color: theme.palette.common.white,
    },
  }));

  const sortedTableRows = useMemo(() => {
    return stableSort<T>(dataRows, getSortComparator() as any).map(
      (row, index) => {
        let key: Key = `${typeof row}_${index}`;
        if (keyFields.length > 0) {
          key = keyFields.map((k) => row[k]).join('_');
        }
        return (
          <TableRow key={key}>
            {headCells.map((headCell) => {
              return (
                <StyledTableCell
                  key={`${String(headCell.id)}_${row[headCell.id]}`}
                  align={
                    headCell.dataAlignment === DataAlignment.Right
                      ? 'right'
                      : 'left'
                  }
                >
                  {!!headCell.icon && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'right',
                      }}
                    >
                      {headCell.icon(row)}
                      {headCell.customCellRenderer
                        ? headCell.customCellRenderer?.(
                            row[headCell.id],
                            headCell,
                            row,
                            format
                          )
                        : format(row[headCell.id] as number | string, headCell)}
                    </Box>
                  )}
                  {!headCell.icon && (
                    <Box>
                      {headCell.customCellRenderer
                        ? headCell.customCellRenderer?.(
                            row[headCell.id],
                            headCell,
                            row,
                            format
                          )
                        : format(row[headCell.id] as number | string, headCell)}
                    </Box>
                  )}
                </StyledTableCell>
              );
            })}
            {actionRenderer ? (
              <StyledTableCell>{actionRenderer(row)}</StyledTableCell>
            ) : null}
          </TableRow>
        );
      }
    );
  }, [dataRows, format, getSortComparator, headCells, keyFields]);

  return (
    <Paper sx={(theme) => ({ width: '100%', marginBottom: theme.spacing(2) })}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750, ...sx }}
          size='medium'
          aria-labelledby='tableTitle'
          aria-label='sortable table'
        >
          <SortableTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            actionRenderer={actionRenderer}
          />
          <TableBody
            sx={(theme) => ({
              '& .MuiTableRow-root': {
                '&:nth-of-type(odd)': {
                  backgroundColor: theme.extensions.grey[24],
                },
              },
            })}
          >
            {sortedTableRows}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default SortableTable;
