/**
 * @generated SignedSource<<bc975a36a9a22e915929704b735046bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type InternalAccessPageWrapperQuery$variables = {};
export type InternalAccessPageWrapperQuery$data = {
  readonly clientsummary_viewer: {
    readonly client: ReadonlyArray<{
      readonly userId: any;
    }>;
  };
  readonly userprofile_viewer: {
    readonly displayName: string;
    readonly firstName: string;
    readonly id: string;
    readonly lastName: string;
  } | null;
};
export type InternalAccessPageWrapperQuery = {
  response: InternalAccessPageWrapperQuery$data;
  variables: InternalAccessPageWrapperQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "ClientModel",
  "kind": "LinkedField",
  "name": "client",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "UserProfileViewer",
  "kind": "LinkedField",
  "name": "userprofile_viewer",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "InternalAccessPageWrapperQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientSummaryViewer",
        "kind": "LinkedField",
        "name": "clientsummary_viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "InternalAccessPageWrapperQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientSummaryViewer",
        "kind": "LinkedField",
        "name": "clientsummary_viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ]
  },
  "params": {
    "cacheID": "76f0fd83cb0647fd8ab777f0767e5a15",
    "id": null,
    "metadata": {},
    "name": "InternalAccessPageWrapperQuery",
    "operationKind": "query",
    "text": "query InternalAccessPageWrapperQuery {\n  clientsummary_viewer {\n    client {\n      userId\n    }\n    id\n  }\n  userprofile_viewer {\n    id\n    firstName\n    lastName\n    displayName\n  }\n}\n"
  }
};
})();

(node as any).hash = "e4e114e48f000801421fc2ea902f2d3a";

export default node;
