import { useQueryLoader } from 'react-relay';
import {
  CashFlowPreviewQueryReader,
  CashFlowPreviewQueryReaderQueryNode,
} from './CashFlowPreviewQueryReader';
import { useParams } from 'react-router';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  CashFlowDetailModelFilterInput,
  CashFlowPreviewQueryReaderQuery,
  CashFlowPreviewQueryReaderQuery$variables,
} from './__generated__/CashFlowPreviewQueryReaderQuery.graphql';
import { FilterItem } from '@newedge/common';
import { DateTime } from 'luxon';
import { GlobalFilterContext } from '../../../components/context/GlobalFilterContext';

export const getCashFlowFilterInput = (): CashFlowDetailModelFilterInput => {
  const now = DateTime.now();
  const startDate = now.startOf('month').plus({ months: 1 });
  const endDate = now.plus({ years: 1 });
  return {
    and: [
      { incomeEstimateDate: { gte: startDate.toFormat('yyyy-MM-dd') } },
      { incomeEstimateDate: { lte: endDate.toFormat('yyyy-MM-dd') } },
    ],
  };
};

const applySecondaryFilters = (
  variables: CashFlowPreviewQueryReaderQuery$variables,
  filterValues: FilterItem[]
): CashFlowPreviewQueryReaderQuery$variables => {
  const newVariables = Object.assign({}, variables);
  let input = newVariables.cashFlowInput;
  const accountIdFilters = filterValues.filter(
    (o) => o.predicateIdentifier === 'accountId'
  );
  if (accountIdFilters.length > 0) {
    const ids: any = new Set();
    accountIdFilters.forEach((o) => {
      if (o.searchValue instanceof Array) {
        o.searchValue.forEach((val) => {
          if (input.financialAccountIds.includes(Number(val))) {
            ids.add(val);
          }
        });
      } else {
        if (input.financialAccountIds.includes(Number(o.searchValue))) {
          ids.add(o.searchValue);
        }
      }
    });
    newVariables.cashFlowInput = {
      financialAccountIds: [...ids],
    };
  }
  return newVariables;
};

export const CashFlowPreviewLoader = () => {
  const [queryRef, loadQuery] = useQueryLoader<CashFlowPreviewQueryReaderQuery>(
    CashFlowPreviewQueryReaderQueryNode
  );

  const [filterValues, setFilterValues] = useState<FilterItem[]>([]);
  const { selectedAccounts } = useContext(GlobalFilterContext);
  const { clientUserId } = useParams();

  const variables = useMemo(() => ({
    input: {
      clientUserIds: [clientUserId],
    },
    clientWhere: {
      userId: { eq: clientUserId },
    },
    cashFlowInput: {
      financialAccountIds: selectedAccounts,
    },
    cashFlowWhere: getCashFlowFilterInput(),
  }), [clientUserId, selectedAccounts]);

  useEffect(() => {
    loadQuery(applySecondaryFilters(variables, filterValues));
  }, [loadQuery, variables, filterValues]);

  if (queryRef) {
    return (
      <CashFlowPreviewQueryReader
        queryRef={queryRef}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
      />
    );
  }
  return <></>;
};
