import { Box, Container, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { PreviewWrapperQuery } from './__generated__/PreviewWrapperQuery.graphql';
import { useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import BackgroundPng from '../../assets/img/bg-img.png';
import {
  ClientPortalLogo,
  GlobalAccountSelect,
  UserProfileAccountGroup,
  UserProfileFinancialAccountNickname,
} from '@newedge/common';
import { ReportPreviewAccordionList } from './ReportPreviewAccordionList';
import { RevertToolbar } from './RevertToolbar';
import combineUserProfileAndClientSummaryFinancialAccountInfo from '../../models/InternalPortalFinancialAccount';
import { InternalFinancialAccountInfo } from '../../@types/global';
import { GlobalFilterContext } from '../../components/context/GlobalFilterContext';

export const PreviewWrapperQueryNode = graphql`
  query PreviewWrapperQuery($input: GetClientUserInput!) {
    userprofile_viewer {
      clientUser(input: $input) {
        id
        displayName
        accounts {
          accountNickname
          financialAccountId
        }
        accountGroups {
          id
          name
          accounts {
            id
            financialAccountId
          }
        }
      }
    }
    clientsummary_viewer {
      client {
        accounts {
          accountDefaultName
          accountId
        }
        userId
      }
    }
  }
`;

interface PreviewWrapperProps {
  queryRef: PreloadedQuery<PreviewWrapperQuery>;
}

export const PreviewWrapper = ({ queryRef }: PreviewWrapperProps) => {
  const data = usePreloadedQuery(PreviewWrapperQueryNode, queryRef);
  const displayName = data.userprofile_viewer?.clientUser[0].displayName;

  const clientUserProfile = data.userprofile_viewer?.clientUser[0];
  const clientSummary = data.clientsummary_viewer.client.find(
    (o) => o.userId === clientUserProfile?.id
  );

  const accountGroups =
    clientUserProfile?.accountGroups as UserProfileAccountGroup[];

  const accounts = combineUserProfileAndClientSummaryFinancialAccountInfo(
    clientUserProfile?.accounts as UserProfileFinancialAccountNickname[],
    accountGroups,
    clientSummary?.accounts as InternalFinancialAccountInfo[]
  );

  const {
    selectedAccounts,
    setSelectedAccounts,
    allAccounts,
    setAllAccounts,
    clientId,
    setClientId,
  } = useContext(GlobalFilterContext);

  const allAccountIds = useMemo(() => {
    return accounts.map((o) => o.financialAccountId);
  }, [accounts]);

  useEffect(() => {
    if (
      allAccountIds &&
      clientId !== data.userprofile_viewer?.clientUser[0].id
    ) {
      setAllAccounts(allAccountIds);
      setSelectedAccounts(allAccountIds);
      setClientId(data.userprofile_viewer?.clientUser[0].id);
    }
  }, [allAccountIds]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={(theme) => ({
          padding: theme.spacing(3),
          width: '90vw',
          maxWidth: '1400px',
        })}
      >
        <RevertToolbar displayName={displayName} />
        <Box sx={{ marginTop: '56px', maxWidth: '1400px', p: 1.5 }}>
          <Box
            sx={{
              justifyContent: 'left',
              width: '17%',
              maxWidth: '1400px',
              paddingBottom: '2rem',
            }}
          >
            <ClientPortalLogo />
          </Box>
          <Box
            component='img'
            sx={{
              position: 'fixed',
              top: '100px',
              left: '50%',
              transform: ' translate(-50%, 0)',
              zIndex: -1,
              height: '100vh',
            }}
            src={`${BackgroundPng}`}
            alt=''
          />
          <Typography
            sx={(theme) => ({ ...theme.typography.special })}
            data-testid='ClientPreviewClientDisplayName'
          >
            {displayName}
          </Typography>
          <Box sx={{ py: 2 }}>
            <GlobalAccountSelect
              accounts={accounts}
              accountGroups={accountGroups}
              selectedAccounts={selectedAccounts}
              setSelectedAccounts={setSelectedAccounts}
              allAccounts={allAccounts}
            />
          </Box>
          <Box sx={{ my: 2 }}>
            <ReportPreviewAccordionList />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const PreviewWrapperLoader = () => {
  const [queryRef, loadQuery] = useQueryLoader<PreviewWrapperQuery>(
    PreviewWrapperQueryNode
  );
  const { clientUserId } = useParams();

  useEffect(() => {
    loadQuery({
      input: {
        clientUserIds: [clientUserId],
      },
    });
  }, [loadQuery, clientUserId]);

  if (queryRef) {
    return <PreviewWrapper queryRef={queryRef} />;
  }
  return <></>;
};

export default PreviewWrapper;
