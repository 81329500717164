import { useQueryLoader } from 'react-relay';
import {
  HoldingsPreviewQueryReader,
  HoldingsPreviewQueryReaderQueryNode,
} from './HoldingsPreviewQueryReader';
import { useLocation, useParams } from 'react-router';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  HoldingsPreviewQueryReaderQuery,
  HoldingsPreviewQueryReaderQuery$variables,
} from './__generated__/HoldingsPreviewQueryReaderQuery.graphql';
import { FilterItem } from '@newedge/common';
import { GlobalFilterContext } from '../../../components/context/GlobalFilterContext';

const applySecondaryFilters = (
  variables: HoldingsPreviewQueryReaderQuery$variables,
  filterValues: FilterItem[]
): HoldingsPreviewQueryReaderQuery$variables => {
  const newVariables = Object.assign({}, variables);

  let input = newVariables.holdingsInput;
  const accountIdFilters = filterValues.filter(
    (o) => o.predicateIdentifier === 'accountId'
  );
  if (accountIdFilters.length > 0) {
    const ids: any = new Set();
    accountIdFilters.forEach((o) => {
      if (o.searchValue instanceof Array) {
        o.searchValue.forEach((val) => {
          if (input.financialAccountIds.includes(Number(val))) {
            ids.add(val);
          }
        });
      } else {
        if (input.financialAccountIds.includes(Number(o.searchValue))) {
          ids.add(o.searchValue);
        }
      }
    });
    newVariables.holdingsInput = {
      financialAccountIds: [...ids],
    };
  }

  let where = newVariables.holdingsWhere;
  const nonAccountIdFilters = filterValues.filter(
    (o) => o.predicateIdentifier !== 'accountId'
  );
  if (nonAccountIdFilters.length > 0) {
    const and: any = [];
    const or: any = [];
    nonAccountIdFilters.forEach((o) => {
      if (o.predicateIdentifier) {
        switch (o.logic) {
          case 'or':
            or.push({
              [o.predicateIdentifier]: { eq: o.searchValue },
            });
            break;
          case 'and':
          case undefined:
          default:
            and.push({
              [o.predicateIdentifier]: { eq: o.searchValue },
            });
        }
      }
    });
    where = {
      or,
      and,
    };
    if (where.and?.length === 0) {
      delete where.and;
    }
    if (where.or?.length === 0) {
      delete where.or;
    }
    newVariables.holdingsWhere = where;
  }

  return newVariables;
};

const getInitialFilterItems = (state: FilterItem[]): FilterItem[] => {
  if (!state) {
    return [];
  }
  return state;
};

export const HoldingsPreviewLoader = () => {
  const [queryRef, loadQuery] = useQueryLoader<HoldingsPreviewQueryReaderQuery>(
    HoldingsPreviewQueryReaderQueryNode
  );
  const { selectedAccounts } = useContext(GlobalFilterContext);
  const { clientUserId } = useParams();

  const variables = useMemo(
    () => ({
      input: {
        clientUserIds: [clientUserId],
      },
      clientWhere: {
        userId: { eq: clientUserId },
      },
      holdingsInput: {
        financialAccountIds: selectedAccounts,
      },
    }),
    [clientUserId, selectedAccounts]
  );

  const { state } = useLocation();

  const initialFilterItems: FilterItem[] = state
    ? getInitialFilterItems(state.secondaryFilters as FilterItem[])
    : [];

  const [filterValues, setFilterValues] =
    useState<FilterItem[]>(initialFilterItems);

  useEffect(() => {
    loadQuery(applySecondaryFilters(variables, filterValues));
  }, [loadQuery, variables, filterValues]);

  if (queryRef) {
    return (
      <HoldingsPreviewQueryReader
        queryRef={queryRef}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        hasInitialFilters={initialFilterItems && initialFilterItems.length > 0}
      />
    );
  }
  return <></>;
};
