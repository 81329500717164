import {
  UserProfileAccountGroup,
  UserProfileFinancialAccountNickname,
} from '@newedge/common';
import graphql from 'babel-plugin-relay/macro';
import { useContext } from 'react';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { ReportPreviewWrapper } from '../ReportPreviewWrapper';
import {
  HouseholdPerformanceModel,
  PerformanceByAccountGroupModel,
  PerformanceByAccountModel,
  PerformanceByAssetClassModel,
  PerformanceByHoldingsModel,
  PerformanceByManagementStyleModel,
  PerformanceByProductModel,
  PerformanceBySubAssetClassModel,
  PerformanceNestedTableRowData,
  PerformanceView,
  projectPerformanceByAccountGroupData,
  projectPerformanceByAssetClassData,
  projectPerformanceByHoldingsData,
  projectPerformanceByManagementStyleData,
  projectPerformanceBySubAssetClassData,
} from '@newedge/reports';
import { PerformancePreviewQueryReaderQuery } from './__generated__/PerformancePreviewQueryReaderQuery.graphql';
import { PerformancePreviewQueryReaderBenchmarkQuery } from './__generated__/PerformancePreviewQueryReaderBenchmarkQuery.graphql';
import combineUserProfileAndClientSummaryFinancialAccountInfo from '../../../models/InternalPortalFinancialAccount';
import { InternalFinancialAccountInfo } from '../../../@types/global';
import { GlobalFilterContext } from '../../../components/context/GlobalFilterContext';

export const PerformancePreviewQueryReaderBenchmarkQueryNode = graphql`
  query PerformancePreviewQueryReaderBenchmarkQuery {
    clientsummary_viewer {
      id
      getPortfolioPerformanceBenchmark {
        asOfDate
        fiveYearReturns
        monthToDate
        oneYearReturns
        portfolioPerformanceBenchmarkId
        quarterToDate
        tenYearReturns
        threeYearReturns
        yearToDate
        benchmarkName
      }
    }
  }
`;

export const PerformancePreviewQueryReaderQueryNode = graphql`
  query PerformancePreviewQueryReaderQuery(
    $input: GetClientUserInput!
    $clientWhere: ClientModelFilterInput
    $performanceInput: GetPortfolioPerformanceInput!
  ) {
    userprofile_viewer {
      clientUser(input: $input) {
        id
        accounts {
          accountNickname
          financialAccountId
        }
        accountGroups {
          id
          name
          accounts {
            id
            financialAccountId
          }
        }
      }
    }
    clientsummary_viewer {
      client(where: $clientWhere) {
        accounts {
          accountDefaultName
          accountId
          accountNumber
          managementStyle
          custodian
          totalBalance
        }
        userId
        getPerformanceByAccount {
            performanceByAccountId
            accountId
            accountName
            managementStyleId
            managementStyle
            custodian
            asOfDate
            marketValue
            monthToDate
            quarterToDate
            yearToDate
            oneYearReturns
            threeYearReturns
            fiveYearReturns
            tenYearReturns
            sinceInception
            inceptionDate
            householdId
            householdName
        }
        getPerformanceByAccountGroup {
          performanceByAccountGroupId
          accountGroupId
          asOfDate
          marketValue
          monthToDate
          quarterToDate
          yearToDate
          oneYearReturns
          threeYearReturns
          fiveYearReturns
          tenYearReturns
          sinceInception
          inceptionDate
        }
        getPerformanceByHousehold {
          performanceByHouseholdId
          asOfDate
          marketValue
          monthToDate
          quarterToDate
          yearToDate
          oneYearReturns
          threeYearReturns
          fiveYearReturns
          tenYearReturns
          sinceInception
          inceptionDate
          householdName
          householdId
        }
        getPortfolioPerformance(input: $performanceInput) {
          accountDefaultName
          accountId
          asOfDate
          custodian
          fiveYearReturns
          inceptionDate
          managementStyle
          marketValue
          monthToDate
          netGross
          oneYearReturns
          portfolioPerformanceId
          quarterToDate
          sinceInception
          tenYearReturns
          threeYearReturns
          yearToDate
        }
        getPerformanceByHoldingsForAccountValue {
          performanceByHoldingsForAccountValueId
          asOfDate
          accountId
          accountName
          custodian
          marketValue
          monthToDate
          quarterToDate
          yearToDate
          oneYearReturns
          threeYearReturns
          fiveYearReturns
          tenYearReturns
          sinceInception
          inceptionDate
          householdName
          productName
          productId
          householdId
        }
        getPerformanceByManagementStyle {
          performanceByManagementStyleId
          asOfDate
          managementStyleId
          managementStyle
          marketValue
          monthToDate
          quarterToDate
          yearToDate
          oneYearReturns
          threeYearReturns
          fiveYearReturns
          tenYearReturns
          sinceInception
          inceptionDate
          householdName
          householdId
        }
        getPerformanceByAssetClassForAssetValue {
          performanceByAssetClassForAssetValueId
          asOfDate
          assetClassId
          assetClassName
          marketValue
          monthToDate
          quarterToDate
          yearToDate
          oneYearReturns
          threeYearReturns
          fiveYearReturns
          tenYearReturns
          sinceInception
          inceptionDate
          householdName
          householdId
        }
        getPerformanceBySubAssetClassForSubAssetValue {
          performanceBySubAssetClassForSubAssetValueId
          asOfDate
          subAssetClassId
          subAssetClassName
          marketValue
          monthToDate
          quarterToDate
          yearToDate
          oneYearReturns
          threeYearReturns
          fiveYearReturns
          tenYearReturns
          sinceInception
          inceptionDate
          householdName
          householdId
        }
        getPerformanceByProduct {
          performanceByProductId
          householdName
          productId
          productName
          assetClassId
          assetClassName
          subAssetClassId
          subAssetClassName
          asOfDate
          inceptionDate
          marketValue
          monthToDate
          quarterToDate
          yearToDate
          oneYearReturns
          threeYearReturns
          fiveYearReturns
          tenYearReturns
          sinceInception
          householdId
        }
      }
    }
  }
`;

interface PerformancePreviewQueryReaderProps {
  performanceQueryRef: PreloadedQuery<PerformancePreviewQueryReaderQuery>;
  benchmarkQueryRef: PreloadedQuery<PerformancePreviewQueryReaderBenchmarkQuery>;
}

export const PerformancePreviewQueryReader = ({
  performanceQueryRef,
  benchmarkQueryRef,
}: PerformancePreviewQueryReaderProps) => {
  const data = usePreloadedQuery(
    PerformancePreviewQueryReaderQueryNode,
    performanceQueryRef
  );

  const benchmarkData = usePreloadedQuery(
    PerformancePreviewQueryReaderBenchmarkQueryNode,
    benchmarkQueryRef
  );

  const mappedBenchmarkData: PerformanceNestedTableRowData[] =
    benchmarkData.clientsummary_viewer.getPortfolioPerformanceBenchmark.map(
      (benchmarkItem) => {
        return {
          name: benchmarkItem.benchmarkName,
          monthToDate: benchmarkItem.monthToDate,
          quarterToDate: benchmarkItem.quarterToDate,
          yearToDate: benchmarkItem.yearToDate,
          oneYearReturns: benchmarkItem.oneYearReturns,
          threeYearReturns: benchmarkItem.threeYearReturns,
          fiveYearReturns: benchmarkItem.fiveYearReturns,
          tenYearReturns: benchmarkItem.tenYearReturns,
        } as PerformanceNestedTableRowData;
      }
    );

  const benchmarkAsOfDate =
    benchmarkData.clientsummary_viewer?.getPortfolioPerformanceBenchmark[0]
      .asOfDate;

  const clientUserProfile = data.userprofile_viewer?.clientUser[0];
  const clientSummary = data.clientsummary_viewer.client.find(
    (o) => o.userId === clientUserProfile?.id
  );

  const accountGroups =
    clientUserProfile?.accountGroups as UserProfileAccountGroup[];

  const accounts = combineUserProfileAndClientSummaryFinancialAccountInfo(
    clientUserProfile?.accounts as UserProfileFinancialAccountNickname[],
    accountGroups,
    clientSummary?.accounts as InternalFinancialAccountInfo[]
  );

  const { selectedAccounts } = useContext(GlobalFilterContext);

  const performanceByHouseholdData = clientSummary?.getPerformanceByHousehold;

  const performanceDataWithNicknames:
    | PerformanceNestedTableRowData[]
    | undefined = clientSummary?.getPerformanceByAccount?.map((o) => {
    const nickname = accounts.find(
      (account) => account.nicknameEntry?.financialAccountId === o.accountId
    )?.nicknameEntry?.accountNickname;
    return {
      ...o,
      name: nickname ? nickname : o.accountName,
    } as PerformanceNestedTableRowData;
  });

  const performanceByAccountGroupData = projectPerformanceByAccountGroupData(
    accounts,
    accountGroups,
    data.clientsummary_viewer.client[0]
      .getPerformanceByAccount as unknown as PerformanceByAccountModel[],
    data.clientsummary_viewer.client[0]
      .getPerformanceByAccountGroup as unknown as PerformanceByAccountGroupModel[]
  );

  const performanceByManagementStyleData =
    projectPerformanceByManagementStyleData(
      performanceDataWithNicknames as PerformanceByManagementStyleModel[],
      data.clientsummary_viewer.client[0]
        .getPerformanceByManagementStyle as PerformanceByManagementStyleModel[],
      !performanceByHouseholdData
        ? []
        : (performanceByHouseholdData as unknown as HouseholdPerformanceModel[])
    );

  const performanceByAssetClassData = projectPerformanceByAssetClassData(
    clientSummary?.getPerformanceByAssetClassForAssetValue as PerformanceByAssetClassModel[],
    clientSummary?.getPerformanceByProduct as PerformanceByProductModel[],
    !performanceByHouseholdData
      ? []
      : (performanceByHouseholdData as unknown as HouseholdPerformanceModel[])
  );

  const performanceBySubAssetClassData = projectPerformanceBySubAssetClassData(
    clientSummary?.getPerformanceBySubAssetClassForSubAssetValue as PerformanceBySubAssetClassModel[],
    clientSummary?.getPerformanceByProduct as PerformanceByProductModel[],
    !performanceByHouseholdData
      ? []
      : (performanceByHouseholdData as unknown as HouseholdPerformanceModel[])
  );

  const performanceByHoldingsData = projectPerformanceByHoldingsData(
    clientSummary?.getPerformanceByHoldingsForAccountValue?.map((o) => {
      const nickname = accounts.find(
        (account) => account.nicknameEntry?.financialAccountId === o.accountId
      )?.nicknameEntry?.accountNickname;
      return {
        ...o,
        name: nickname ? nickname : o.accountName,
      };
    }) as unknown as PerformanceByHoldingsModel[],
    clientSummary?.getPerformanceByProduct as PerformanceByProductModel[],
    performanceByHouseholdData as unknown as HouseholdPerformanceModel[]
  );

  return (
    <ReportPreviewWrapper>
      <PerformanceView
        orionApiServiceData={{}}
        benchmarkData={mappedBenchmarkData}
        benchmarkAsOfDate={benchmarkAsOfDate}
        accounts={accounts}
        selectedAccounts={selectedAccounts}
        accountGroupPerformanceData={performanceByAccountGroupData}
        performanceByAssetClassData={performanceByAssetClassData}
        performanceBySubAssetClassData={performanceBySubAssetClassData}
        performanceByHoldingsData={performanceByHoldingsData}
        performanceByManagementStyleData={performanceByManagementStyleData}
      />
    </ReportPreviewWrapper>
  );
};
