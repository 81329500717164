import { Box, Skeleton } from '@mui/material';
import { ReportPreviewAccordion } from '../../components/accordion/ReportPreviewAccordion';
import { CashFlowPreviewLoader } from './cash-flow/CashFlowPreviewLoader';
import { HoldingsPreviewLoader } from './holdings/HoldingsPreviewLoader';
import { AssetAllocationPreviewLoader } from './asset-allocation/AssetAllocationPreviewLoader';
import { Suspense } from 'react';
import { AccountBalancesPreviewLoader } from './account-balances/AccountBalancesPreviewLoader';
import { AccountActivityPreviewLoader } from './account-activity/AccountActivityPreviewLoader';
import { PerformancePreviewLoader } from './performance/PerformancePreviewLoader';

const reports = [
  {
    title: 'Expected Cash Flow',
    node: <CashFlowPreviewLoader />,
  },
  { title: 'Asset Allocation', node: <AssetAllocationPreviewLoader /> },
  { title: 'Holdings', node: <HoldingsPreviewLoader /> },
  { title: 'Account Activity', node: <AccountActivityPreviewLoader /> },
  { title: 'Account Balances', node: <AccountBalancesPreviewLoader /> },
  ...(process.env.REACT_APP_HIDE_REPORT_PORTFOLIO_CHANGE !== 'true'
    ? [
        {
          title: 'Portfolio Change',
          node: <Box sx={{ p: 2 }}>Coming soon</Box>,
        },
      ]
    : []),
  ...(process.env.REACT_APP_HIDE_REPORT_PERFORMANCE !==
  'true'
    ? [{ title: 'Performance Report', node: <PerformancePreviewLoader /> }]
    : []),
  ...(process.env.REACT_APP_HIDE_REPORT_TAX_INSIGHTS !== 'true'
    ? [{ title: 'Tax Insights', node: <Box sx={{ p: 2 }}>Coming soon</Box> }]
    : []),
];

export const ReportPreviewAccordionList = () => {
  return (
    <>
      {reports.map((report) => {
        return (
          <ReportPreviewAccordion key={report.title} title={report.title}>
            <Suspense
              fallback={
                <Skeleton variant='rectangular' width='100%' height={200} />
              }
            >
              {report.node}
            </Suspense>
          </ReportPreviewAccordion>
        );
      })}
    </>
  );
};
