import { Box, Grid } from '@mui/material';
import { AsOfDate } from '../../components';
import {
  ReportTableTabs,
  TabPanel,
  ExportToExcelButton,
  ExcelAccountItem,
  ClientPortalFinancialAccount,
} from '@newedge/common';
import {
  AcctGroupsPanelContent,
  AssetClassPanelContent,
  HoldingsPanelContent,
  ManagementStylePanelContent,
  ReportTypes,
  SubAssetClassPanelContent,
} from './performance-tabs';
import BenchmarkTable from './benchmark-table/BenchmarkTable';
import { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router';
import {
  HouseholdTableRowData,
  OrionApiPerformanceStatusData,
  PerformanceNestedTableRowData,
  PerformanceTableRowData,
} from './@types';
import {
  PerformanceExcelData,
  computePerformanceExcelDataLevel1,
  computePerformanceExcelDataLevel2,
  computePerformanceExcelDataLevel3,
} from './performanceHelpers';
import { defaultReportDisclosuresTextLines } from '../../assets/text/ReportDisclosures';

interface LocationState {
  selectedTab?: string;
}

interface ExcelAttributes {
  sheetTitleTab1: string;
  sheetTitleTab2: string;
  sheetTitleTab3: string;
  tab1Name: string;
  tab2Name: string;
  tab3Name: string;
  filenamePrefix: string;
  tab1CustomHeaders: PerformanceExcelData;
  tab2CustomHeaders: PerformanceExcelData;
  tab3CustomHeaders: PerformanceExcelData;
}

interface PerformanceDesktopViewProps {
  orionApiServiceData: OrionApiPerformanceStatusData;
  accountGroupPerformanceData: PerformanceTableRowData[];
  assetClassPerformanceData: HouseholdTableRowData[];
  subAssetClassPerformanceData: HouseholdTableRowData[];
  benchmarkAsOfDate: any;
  benchmarkData: PerformanceNestedTableRowData[];
  holdingsPerformanceData: HouseholdTableRowData[];
  managementStylePerformanceData: HouseholdTableRowData[];
  accounts: ClientPortalFinancialAccount[];
  selectedAccounts: number[];
}

export const PerformanceDesktopView = ({
  orionApiServiceData,
  accountGroupPerformanceData,
  assetClassPerformanceData,
  subAssetClassPerformanceData,
  benchmarkAsOfDate,
  benchmarkData,
  holdingsPerformanceData,
  managementStylePerformanceData,
  accounts,
  selectedAccounts,
}: PerformanceDesktopViewProps) => {
  const location = useLocation();
  const state = location.state as LocationState;

  let initialTab = ReportTypes.AccountGroups;
  if (state && state.selectedTab) {
    initialTab = state.selectedTab as ReportTypes;
  }

  const [selectedTab, setSelectedTab] = useState<string>(initialTab);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  const [headCellWidths, setHeadCellWidths] = useState({});

  const [asOfDate, setAsOfDate] = useState<string | null>();
  const [currentPerformanceData, setCurrentPerformanceData] =
    useState<PerformanceTableRowData[]>();
  const [excelAttributes, setExcelAttributes] = useState<ExcelAttributes>();

  const getBlankPerformanceExcelData = () => {
    return {
      Column1: '',
      Column2: '',
      Column3: '',
      'Market Value': '',
      'Month To Date': '',
      'Quarter To Date': '',
      'Year To Date': '',
      'One Year Returns': '',
      'Three Year Returns': '',
      'Five Year Returns': '',
      'Ten Year Returns': '',
      'Since Inception': '',
      'Inception Date': '',
    };
  };

  useEffect(() => {
    if (selectedTab === ReportTypes.AccountGroups) {
      if (accountGroupPerformanceData.length > 0)
        setAsOfDate(accountGroupPerformanceData[0].asOfDate);
      setCurrentPerformanceData(accountGroupPerformanceData);
      let customHeaders = getBlankPerformanceExcelData();
      customHeaders.Column1 = 'Account Group';
      customHeaders.Column2 = 'Account Name';
      setExcelAttributes({
        filenamePrefix: 'PerformanceByAcctGroups',
        sheetTitleTab1: 'Performance by Acct Groups',
        sheetTitleTab2: 'Performance by Acct Groups',
        sheetTitleTab3: 'Performance by Acct Groups',
        tab1Name: 'Acct Groups',
        tab2Name: 'Account Name',
        tab3Name: '',
        tab1CustomHeaders: customHeaders,
        tab2CustomHeaders: customHeaders,
        tab3CustomHeaders: customHeaders,
      });
    }
    if (selectedTab === ReportTypes.ManagementStyle) {
      if (managementStylePerformanceData.length > 0)
        setAsOfDate(managementStylePerformanceData[0].asOfDate);
      setCurrentPerformanceData(managementStylePerformanceData);
      let customHeaders = getBlankPerformanceExcelData();
      customHeaders.Column1 = 'Household';
      customHeaders.Column2 = 'Mgmt Style';
      customHeaders.Column3 = 'Account Name';
      setExcelAttributes({
        filenamePrefix: 'PerformanceByMgmtStyle',
        sheetTitleTab1: 'Performance by Mgmt Style',
        sheetTitleTab2: 'Performance by Mgmt Style',
        sheetTitleTab3: 'Performance by Mgmt Style',
        tab1Name: 'Household',
        tab2Name: 'Mgmt Style',
        tab3Name: 'Account',
        tab1CustomHeaders: customHeaders,
        tab2CustomHeaders: customHeaders,
        tab3CustomHeaders: customHeaders,
      });
    }
    if (selectedTab === ReportTypes.AssetClass) {
      if (assetClassPerformanceData.length > 0)
        setAsOfDate(assetClassPerformanceData[0].asOfDate);
      setCurrentPerformanceData(assetClassPerformanceData);
      let customHeaders = getBlankPerformanceExcelData();
      customHeaders.Column1 = 'Household';
      customHeaders.Column2 = 'Asset Class';
      customHeaders.Column3 = 'Portfolio Holdings';
      setExcelAttributes({
        filenamePrefix: 'PerformanceByAssetClass',
        sheetTitleTab1: 'Performance by Asset Class',
        sheetTitleTab2: 'Performance by Asset Class',
        sheetTitleTab3: 'Performance by Asset Class',
        tab1Name: 'Household',
        tab2Name: 'Asset Class',
        tab3Name: 'Portfolio Holdings',
        tab1CustomHeaders: customHeaders,
        tab2CustomHeaders: customHeaders,
        tab3CustomHeaders: customHeaders,
      });
    }
    if (selectedTab === ReportTypes.SubAssetClass) {
      if (subAssetClassPerformanceData.length > 0)
        setAsOfDate(subAssetClassPerformanceData[0].asOfDate);
      setCurrentPerformanceData(subAssetClassPerformanceData);
      let customHeaders = getBlankPerformanceExcelData();
      customHeaders.Column1 = 'Household';
      customHeaders.Column2 = 'Sub-Asset Class';
      customHeaders.Column3 = 'Portfolio Holdings';
      setExcelAttributes({
        filenamePrefix: 'PerformanceBySubAssetClass',
        sheetTitleTab1: 'Performance by Sub-Asset Class',
        sheetTitleTab2: 'Performance by Sub-Asset Class',
        sheetTitleTab3: 'Performance by Sub-Asset Class',
        tab1Name: 'Household',
        tab2Name: 'Sub-Asset Class',
        tab3Name: 'Portfolio Holdings',
        tab1CustomHeaders: customHeaders,
        tab2CustomHeaders: customHeaders,
        tab3CustomHeaders: customHeaders,
      });
    }
    if (selectedTab === ReportTypes.Holdings) {
      if (holdingsPerformanceData.length > 0)
        setAsOfDate(holdingsPerformanceData[0].asOfDate);
      setCurrentPerformanceData(holdingsPerformanceData);
      let customHeaders = getBlankPerformanceExcelData();
      customHeaders.Column1 = 'Household';
      customHeaders.Column2 = 'Portfolio Holdings';
      customHeaders.Column3 = 'Account Holdings';
      setExcelAttributes({
        filenamePrefix: 'PerformanceByHoldings',
        sheetTitleTab1: 'Performance by Holdings',
        sheetTitleTab2: 'Performance by Holdings',
        sheetTitleTab3: 'Performance by Holdings',
        tab1Name: 'Household',
        tab2Name: 'Portfolio Holdings',
        tab3Name: 'Account Holdings',
        tab1CustomHeaders: customHeaders,
        tab2CustomHeaders: customHeaders,
        tab3CustomHeaders: customHeaders,
      });
    }
  }, [
    selectedTab,
    accountGroupPerformanceData,
    managementStylePerformanceData,
    assetClassPerformanceData,
    subAssetClassPerformanceData,
    holdingsPerformanceData,
  ]);

  const selectedAccountList = useMemo(() => {
    const accountList: ExcelAccountItem[] = [];
    selectedAccounts.forEach((acctId) => {
      const accountInfo = accounts.find((o) => o.financialAccountId === acctId);

      if (accountInfo) {
        accountList.push({
          accountNumber: accountInfo.accountNumber,
          accountName: accountInfo.nicknameEntry
            ? accountInfo.nicknameEntry.accountNickname
            : accountInfo.accountName,
          custodian: accountInfo.custodian ?? '',
        });
      }
    });
    return accountList;
  }, [accounts, selectedAccounts]);

  const excelDataLevel1 = useMemo(() => {
    if (currentPerformanceData)
      return computePerformanceExcelDataLevel1(currentPerformanceData);
    return undefined;
  }, [currentPerformanceData]);

  const excelDataLevel2 = useMemo(() => {
    if (currentPerformanceData)
      return computePerformanceExcelDataLevel2(currentPerformanceData);
    return undefined;
  }, [currentPerformanceData]);

  const excelDataLevel3 = useMemo(() => {
    if (selectedTab === ReportTypes.AccountGroups) return undefined;
    if (currentPerformanceData)
      return computePerformanceExcelDataLevel3(currentPerformanceData);
    return undefined;
  }, [currentPerformanceData]);

  return (
    <>
      <Grid item xs={4}>
        <Box sx={{ width: '100%', display: 'grid' }}>
          <Box sx={{ justifySelf: 'flex-end' }}>
            {excelDataLevel1 && (
              <ExportToExcelButton<PerformanceExcelData>
                data={excelDataLevel1}
                data2={excelDataLevel2}
                data3={excelDataLevel3}
                accounts={selectedAccountList}
                accountListColumns={[
                  {
                    key: 'accountNumber',
                    label: 'Account Number',
                  },
                  {
                    key: 'accountName',
                    label: 'Account Name',
                  },
                  {
                    key: 'custodian',
                    label: 'Custodian',
                  },
                ]}
                filenamePrefix={excelAttributes?.filenamePrefix ?? ''}
                dataSheetTitle={excelAttributes?.sheetTitleTab1 ?? ''}
                dataSheetTitle2={excelAttributes?.sheetTitleTab2 ?? ''}
                dataSheetTitle3={excelAttributes?.sheetTitleTab3 ?? ''}
                columnsToAutoSize={[
                  'Column1',
                  'Column2',
                  'Column3',
                  'Market Value',
                  'Month To Date',
                  'Quarter To Date',
                  'Year To Date',
                  'One Year Returns',
                  'Three Year Returns',
                  'Five Year Returns',
                  'Five Year Returns',
                  'Since Inception',
                  'Inception Date',
                ]}
                disclosuresTextLines={defaultReportDisclosuresTextLines}
                dataCustomHeaders={excelAttributes?.tab1CustomHeaders}
                dataCustomHeaders2={excelAttributes?.tab2CustomHeaders}
                dataCustomHeaders3={excelAttributes?.tab3CustomHeaders}
                tabName={excelAttributes?.tab1Name}
                tabName2={excelAttributes?.tab2Name}
                tabName3={excelAttributes?.tab3Name}
                columnsToHide={['Column2', 'Column3']}
                columnsToHide2={['Column3']}
              />
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ marginBottom: '3rem' }}>
          <Box sx={{ textAlign: 'right' }}>
            <AsOfDate asOfDate={asOfDate} />
          </Box>
          <ReportTableTabs
            value={selectedTab}
            onChange={handleChange}
            tabs={[
              { id: ReportTypes.AccountGroups, label: 'Acct Groups' },
              { id: ReportTypes.ManagementStyle, label: 'Mgmt Style' },
              { id: ReportTypes.AssetClass, label: 'Asset Class' },
              { id: ReportTypes.SubAssetClass, label: 'Sub-Asset Class' },
              { id: ReportTypes.Holdings, label: 'Holdings' },
            ]}
          />
          <TabPanel
            tabName={ReportTypes.AccountGroups}
            value={selectedTab}
            boxWrapperProps={{ padding: 0 }}
          >
            <AcctGroupsPanelContent
              status={orionApiServiceData.accountGroups}
              accountGroupPerformanceData={accountGroupPerformanceData}
              setHeadCellWidths={setHeadCellWidths}
            />
          </TabPanel>
          <TabPanel
            tabName={ReportTypes.ManagementStyle}
            value={selectedTab}
            boxWrapperProps={{ padding: 0 }}
          >
            <ManagementStylePanelContent
              managementStylePerformanceData={managementStylePerformanceData}
              setHeadCellWidths={setHeadCellWidths}
            />
          </TabPanel>
          <TabPanel
            tabName={ReportTypes.AssetClass}
            value={selectedTab}
            boxWrapperProps={{ padding: 0 }}
          >
            <AssetClassPanelContent
              assetClassPerformanceData={assetClassPerformanceData}
              setHeadCellWidths={setHeadCellWidths}
            />
          </TabPanel>
          <TabPanel
            tabName={ReportTypes.SubAssetClass}
            value={selectedTab}
            boxWrapperProps={{ padding: 0 }}
          >
            <SubAssetClassPanelContent
              subAssetClassPerformanceData={subAssetClassPerformanceData}
              setHeadCellWidths={setHeadCellWidths}
            />
          </TabPanel>
          <TabPanel
            tabName={ReportTypes.Holdings}
            value={selectedTab}
            boxWrapperProps={{ padding: 0 }}
          >
            <HoldingsPanelContent
              holdingsPerformanceData={holdingsPerformanceData}
              setHeadCellWidths={setHeadCellWidths}
            />
          </TabPanel>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <AsOfDate asOfDate={benchmarkAsOfDate} />
        </Box>
        <BenchmarkTable data={benchmarkData} headCellWidths={headCellWidths} />
      </Grid>
    </>
  );
};
