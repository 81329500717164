/**
 * @generated SignedSource<<3245c4b204f50ad37eed34b729e427ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GetClientUserInput = {
  clientUserIds: ReadonlyArray<any>;
};
export type EditClientInfoConfigurationViewQuery$variables = {
  getClientUserInput: GetClientUserInput;
};
export type EditClientInfoConfigurationViewQuery$data = {
  readonly clientsummary_viewer: {
    readonly client: ReadonlyArray<{
      readonly summary: {
        readonly netAssets: any;
      } | null;
      readonly userId: any;
    }>;
  };
  readonly userprofile_viewer: {
    readonly clientUser: ReadonlyArray<{
      readonly displayName: string;
      readonly id: any;
    }>;
  } | null;
};
export type EditClientInfoConfigurationViewQuery = {
  response: EditClientInfoConfigurationViewQuery$data;
  variables: EditClientInfoConfigurationViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "getClientUserInput"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "ClientModel",
  "kind": "LinkedField",
  "name": "client",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientSummaryModel",
      "kind": "LinkedField",
      "name": "summary",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "netAssets",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "input",
      "variableName": "getClientUserInput"
    }
  ],
  "concreteType": "ClientUserViewModel",
  "kind": "LinkedField",
  "name": "clientUser",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditClientInfoConfigurationViewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientSummaryViewer",
        "kind": "LinkedField",
        "name": "clientsummary_viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserProfileViewer",
        "kind": "LinkedField",
        "name": "userprofile_viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditClientInfoConfigurationViewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientSummaryViewer",
        "kind": "LinkedField",
        "name": "clientsummary_viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserProfileViewer",
        "kind": "LinkedField",
        "name": "userprofile_viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e93962aef184cd591f7dc636fc173a88",
    "id": null,
    "metadata": {},
    "name": "EditClientInfoConfigurationViewQuery",
    "operationKind": "query",
    "text": "query EditClientInfoConfigurationViewQuery(\n  $getClientUserInput: GetClientUserInput!\n) {\n  clientsummary_viewer {\n    client {\n      userId\n      summary {\n        netAssets\n      }\n    }\n    id\n  }\n  userprofile_viewer {\n    clientUser(input: $getClientUserInput) {\n      id\n      displayName\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5c36c224381a54dbb2ae37fba7eb234e";

export default node;
