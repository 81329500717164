/**
 * @generated SignedSource<<1e8d98dfbb722eee65208ed76dc686b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GetClientUserInput = {
  clientUserIds: ReadonlyArray<any>;
};
export type AccountGroupEditViewQuery$variables = {
  getClientUserInput: GetClientUserInput;
};
export type AccountGroupEditViewQuery$data = {
  readonly clientsummary_viewer: {
    readonly client: ReadonlyArray<{
      readonly accounts: ReadonlyArray<{
        readonly accountDefaultName: string;
        readonly accountId: number;
        readonly accountNumber: string | null;
        readonly accountType: string | null;
        readonly managementStyle: string | null;
        readonly totalBalance: any;
      } | null> | null;
      readonly summary: {
        readonly netAssets: any;
      } | null;
      readonly userId: any;
    }>;
  };
  readonly userprofile_viewer: {
    readonly clientUser: ReadonlyArray<{
      readonly accountGroups: ReadonlyArray<{
        readonly accounts: ReadonlyArray<{
          readonly financialAccountId: number;
          readonly id: any;
        }>;
        readonly id: string;
        readonly name: string;
      }>;
      readonly accounts: ReadonlyArray<{
        readonly accountNickname: string;
        readonly financialAccountId: number;
      }>;
      readonly displayName: string;
      readonly id: any;
    }>;
    readonly displayName: string;
  } | null;
};
export type AccountGroupEditViewQuery = {
  response: AccountGroupEditViewQuery$data;
  variables: AccountGroupEditViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "getClientUserInput"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "ClientModel",
  "kind": "LinkedField",
  "name": "client",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientAccountModel",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountDefaultName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "managementStyle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalBalance",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientSummaryModel",
      "kind": "LinkedField",
      "name": "summary",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "netAssets",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "financialAccountId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "input",
      "variableName": "getClientUserInput"
    }
  ],
  "concreteType": "ClientUserViewModel",
  "kind": "LinkedField",
  "name": "clientUser",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserAccount",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountNickname",
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountGroup",
      "kind": "LinkedField",
      "name": "accountGroups",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Account",
          "kind": "LinkedField",
          "name": "accounts",
          "plural": true,
          "selections": [
            (v4/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountGroupEditViewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientSummaryViewer",
        "kind": "LinkedField",
        "name": "clientsummary_viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserProfileViewer",
        "kind": "LinkedField",
        "name": "userprofile_viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountGroupEditViewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientSummaryViewer",
        "kind": "LinkedField",
        "name": "clientsummary_viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserProfileViewer",
        "kind": "LinkedField",
        "name": "userprofile_viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v5/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "af257d8e9741463b5a2deef6609e19c3",
    "id": null,
    "metadata": {},
    "name": "AccountGroupEditViewQuery",
    "operationKind": "query",
    "text": "query AccountGroupEditViewQuery(\n  $getClientUserInput: GetClientUserInput!\n) {\n  clientsummary_viewer {\n    client {\n      userId\n      accounts {\n        accountDefaultName\n        accountId\n        accountNumber\n        accountType\n        managementStyle\n        totalBalance\n      }\n      summary {\n        netAssets\n      }\n    }\n    id\n  }\n  userprofile_viewer {\n    displayName\n    clientUser(input: $getClientUserInput) {\n      id\n      displayName\n      accounts {\n        accountNickname\n        financialAccountId\n      }\n      accountGroups {\n        accounts {\n          financialAccountId\n          id\n        }\n        id\n        name\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "02ef86c4df8779366f143021977008c3";

export default node;
