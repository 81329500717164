import { Box, styled, TextField, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useMutation } from 'react-relay';
import CustomButton from '../../buttons/CustomButton';
import { v4 as uuidv4 } from 'uuid';
import graphql from 'babel-plugin-relay/macro';
import { CreateAccountGroupInputView_CreateClientAccountGroupMutation } from './__generated__/CreateAccountGroupInputView_CreateClientAccountGroupMutation.graphql';
import { validateAccountGroupName } from '@newedge/common';

interface CreateAccountGroupInputProps {
  label: string;
  clientAccountGroups: string[];
  clientId: string;
  displayName: string;
  handleModalClose: () => void | undefined;
}

export const CreateClientAccountGroup = graphql`
  mutation CreateAccountGroupInputView_CreateClientAccountGroupMutation(
    $createClientAccountGroupInput: CreateClientAccountGroupInput!
  ) {
    CreateClientAccountGroup(input: $createClientAccountGroupInput) {
      accountGroup {
        id
        name
        accounts {
          id
          financialAccountId
        }
      }
    }
  }
`;

const StyledForm = styled('form')({
  alignItems: 'center',
});

const StyledCustomButton = styled(CustomButton)(
  ({
    theme: {
      spacing,
      extensions: { color },
    },
  }) => ({
    marginLeft: spacing(1),
  })
);

const convertGuidToRelayId = (typeName: string, guid: string) => {
  const guidString = guid.replace(/-/g, '');
  return btoa(`${typeName}\ng${guidString}`);
};

export const CreateAccountGroupInput = ({
  label,
  clientAccountGroups,
  clientId,
  displayName,
  handleModalClose,
}: CreateAccountGroupInputProps) => {
  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    handleCreateAccountGroup(textFieldValue);
  };

  const [textFieldValue, setTextFieldValue] = useState('');
  const [hasCreateErrors, setHasCreateErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const resetState = () => {
    setHasCreateErrors(false);
    setErrorMessage('');
    setTextFieldValue('');
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;

    let errorMessage = validateAccountGroupName(value, clientAccountGroups);

    if (errorMessage) {
      setHasCreateErrors(true);
      setErrorMessage(errorMessage);
    } else {
      setHasCreateErrors(false);
      setErrorMessage('');
    }

    setTextFieldValue(value);
  };

  const handleCreateAccountGroup = async (newName: string) => {
    const guid = uuidv4();
    let errorMessage = validateAccountGroupName(newName, clientAccountGroups);

    if (errorMessage) {
      setHasCreateErrors(true);
      setErrorMessage(errorMessage);
      return;
    }

    setHasCreateErrors(false);
    setErrorMessage('');
    await createAccountGroup(newName, guid);
    setTextFieldValue('');
    handleModalClose();
  };

  const [commitCreateClientAccountGroup] =
    useMutation<CreateAccountGroupInputView_CreateClientAccountGroupMutation>(
      CreateClientAccountGroup
    );

  const createAccountGroup = useCallback(
    async (name: string, guid: string) => {
      commitCreateClientAccountGroup({
        variables: {
          createClientAccountGroupInput: {
            accountGroupId: guid,
            accountGroupName: name,
            clientDisplayName: displayName,
            clientId,
          },
        },
        updater: (store) => {
          const root = store.getRoot();
          const userProfileViewer = root.getLinkedRecord('userprofile_viewer');
          const clientUser = userProfileViewer?.getLinkedRecords(
            `clientUser(input:{"clientUserIds":["${clientId}"]})`
          );
          if (clientUser && clientUser[0]) {
            const accountGroups =
              clientUser[0].getLinkedRecords('accountGroups');
            const addedAccountGroup = store.get(
              convertGuidToRelayId('AccountGroup', guid)
            );
            clientUser[0].setLinkedRecords(
              [...accountGroups!, addedAccountGroup!],
              'accountGroups'
            );
          }
        },
      });
    },
    [commitCreateClientAccountGroup]
  );

  return (
    <Box>
      <StyledForm
        sx={{ paddingY: '0.5rem', display: 'flex', justifyContent: 'center' }}
        onSubmit={onSubmit}
      >
        <TextField
          error={!!hasCreateErrors}
          id='add-account-group'
          label={label}
          value={textFieldValue}
          onChange={handleTextFieldChange}
          sx={(theme) => ({
            width: '24rem',
            input: {
              color: theme.palette.primary.contrastText,
              '&::placeholder': {
                opacity: 0.75,
              },
            },
          })}
          variant='outlined'
          color='secondary'
          size={'small'}
        />
        <StyledCustomButton
          variant='md'
          buttonProps={{
            onClick: () => handleCreateAccountGroup(textFieldValue),
            variant: 'contained',
            color: 'secondary',
            disabled: hasCreateErrors,
          }}
        >
          Add
        </StyledCustomButton>

        <StyledCustomButton
          variant='md'
          buttonProps={{
            onClick: () => {
              resetState();
            },
            variant: 'contained',
            color: 'secondary',
          }}
        >
          Clear
        </StyledCustomButton>
      </StyledForm>
      {errorMessage && (
        <Typography
          sx={{
            width: '100%',
            color: '#f44336',
            display: hasCreateErrors ? 'block' : 'none',
          }}
        >
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

export default CreateAccountGroupInput;
