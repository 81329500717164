import { Box, Typography } from '@mui/material';
import { NumberFormatType, formatNumber } from '@newedge/common';

interface ClientSummaryInfoProps {
  displayName: string;
  netAssets: number;
}

export const ClientSummaryInfo = ({
  displayName,
  netAssets,
}: ClientSummaryInfoProps) => {
  return (
    <>
      <Box sx={{ mx: 1, my: 2 }}>
        <Typography sx={{ fontWeight: 'bold' }}>Client Name</Typography>
        <Typography data-testid='ClientSummaryDisplayName'>
          {displayName}
        </Typography>
      </Box>
      <Box sx={{ mx: 1, my: 2, paddingLeft: 8 }}>
        <Typography sx={{ fontWeight: 'bold' }}>Net Assets</Typography>
        <Typography data-testid='ClientSummaryNetAssets'>
          {formatNumber(netAssets, NumberFormatType.Currency)}
        </Typography>
      </Box>
    </>
  );
};
