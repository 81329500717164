import {
  ClientPortalFinancialAccount,
  MobileReportTableColumn,
  NumberFormatType,
  TooltipWrapper,
  UserProfileAccountGroup,
  formatNumber,
} from '@newedge/common';
import {
  PerformanceByAssetClassModel,
  PerformanceBySubAssetClassModel,
  PerformanceByHoldingsModel,
  PerformanceNestedTableRowData,
  PerformanceTableRowData,
  PerformanceByManagementStyleModel,
  HouseholdPerformanceModel,
  HouseholdTableRowData,
  PerformanceByAccountGroupModel,
  PerformanceByProductModel,
  PerformanceByAccountModel,
} from './@types';
import { DateTime } from 'luxon';
import { Box, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';

export const positiveNumberDecorator = (value: number) =>
  value > 0 ? '+' : '';

export const cellNumberFormatter = (value?: number | string | null) =>
  value === null || value === undefined || typeof value === 'string'
    ? '--'
    : formatNumber(value, NumberFormatType.Percent, 2, 2);

export const cellPositiveNumberDecorator = (value?: number | string | null) =>
  value === null || value === undefined || typeof value === 'string'
    ? ''
    : positiveNumberDecorator(value);

export const cellValueFormatter = (value?: number | string | null) => {
  return (
    <>
      {cellPositiveNumberDecorator(value)}
      {cellNumberFormatter(value)}
    </>
  );
};

export const excelValueFormatter = (value?: number | string | null) => {
  return cellPositiveNumberDecorator(value) + cellNumberFormatter(value);
};

export interface PerformanceExcelData {
  'Column1': string;
  'Column2': string;
  'Column3': string;
  'Market Value': string;
  'Month To Date': string;
  'Quarter To Date': string;
  'Year To Date': string;
  'One Year Returns': string;
  'Three Year Returns': string;
  'Five Year Returns': string;
  'Ten Year Returns': string;
  'Since Inception': string;
  'Inception Date': string;
}

export const computePerformanceExcelDataLevel1 = (
  tableData: PerformanceTableRowData[]
): PerformanceExcelData[] => {
  const excelData: PerformanceExcelData[] = [];
  tableData.forEach((o) => {
    let level1Row = computePerformanceExcelDataSingleRow(
      o,
      o.name ?? '',
      '',
      ''
    );
    excelData.push(level1Row);
  });

  return excelData;
};

export const computePerformanceExcelDataLevel2 = (
  tableData: PerformanceTableRowData[]
): PerformanceExcelData[] => {
  const excelData: PerformanceExcelData[] = [];
  tableData.forEach((o) => {
    o.nestedData?.forEach((o2) => {
      let level2Row = computePerformanceExcelDataSingleRow(
        o2,
        o.name ?? '',
        o2.name ?? '',
        ''
      );
      excelData.push(level2Row);
    });
  });

  return excelData;
};

export const computePerformanceExcelDataLevel3 = (
  tableData: PerformanceTableRowData[]
): PerformanceExcelData[] => {
  const excelData: PerformanceExcelData[] = [];
  tableData.forEach((o) => {
    o.nestedData?.forEach((o2) => {
      o2.nestedData?.forEach((o3) => {
        let level3Row = computePerformanceExcelDataSingleRow(
          o3,
          o.name ?? '',
          o2.name ?? '',
          o3.name ?? ''
        );
        excelData.push(level3Row);
      });
    });
  });

  return excelData;
};

const computePerformanceExcelDataSingleRow = (
  o: PerformanceTableRowData,
  valColumn1: string,
  valColumn2: string,
  valColumn3: string
) => {
  return {
    'Column1': valColumn1,
    'Column2': valColumn2,
    'Column3': valColumn3,
    'Market Value': o.marketValue
      ? formatNumber(o.marketValue, NumberFormatType.Currency, 0, 0)
      : '--',
    'Month To Date': excelValueFormatter(o.monthToDate),
    'Quarter To Date': excelValueFormatter(o.quarterToDate),
    'Year To Date': excelValueFormatter(o.yearToDate),
    'One Year Returns': excelValueFormatter(o.oneYearReturns),
    'Three Year Returns': excelValueFormatter(o.threeYearReturns),
    'Five Year Returns': excelValueFormatter(o.fiveYearReturns),
    'Ten Year Returns': excelValueFormatter(o.tenYearReturns),
    'Since Inception': excelValueFormatter(o.sinceInception),
    'Inception Date': !o.inceptionDate
      ? '--'
      : DateTime.fromISO(o.inceptionDate).toFormat('M/d/yyyy'),
  }
}

export const getMobileNameColumn = (
  nestedRow: PerformanceNestedTableRowData
) => {
  return (
    <>
      {nestedRow.custodian && nestedRow.name.length <= 72 ? (
        <Tooltip
          sx={{ fontSize: '1.4rem' }}
          title={<Box sx={{ fontSize: '1.4rem' }}>{nestedRow.custodian}</Box>}
        >
          <Typography sx={{ fontSize: '1.4rem', overflowWrap: 'anywhere' }}>
            {nestedRow.name}
          </Typography>
        </Tooltip>
      ) : (
        <TooltipWrapper
          text={nestedRow.name}
          maxLength={72}
          typographyVariant='link'
          tooltipRenderer={
            nestedRow.custodian
              ? (text) => (
                  <Box
                    sx={{
                      fontSize: '1.4rem',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <span>
                      <Box sx={{ fontWeight: 'bold' }}>Account Name:</Box>{' '}
                      {text}
                    </span>
                    <span>
                      <Box sx={{ fontWeight: 'bold' }}>Custodian:</Box>{' '}
                      {nestedRow.custodian}
                    </span>
                  </Box>
                )
              : undefined
          }
        />
      )}
    </>
  );
};

export const nestedTableHeaderRenderer = (
  columns: MobileReportTableColumn<
    PerformanceTableRowData,
    PerformanceNestedTableRowData
  >[],
  nestedRow: PerformanceNestedTableRowData
) => {
  return (
    <Box
      sx={(theme) => ({
        paddingLeft: 2,
        paddingBottom: 1,
        paddingTop: 1,
        ...theme.typography.body1,
      })}
    >
      {columns.map((column) => {
        return (
          <>
            {column?.nestedId === 'name'
              ? getMobileNameColumn(nestedRow)
              : null}
          </>
        );
      })}
    </Box>
  );
};

const mapTableRow = (
  accountGroup: UserProfileAccountGroup,
  nestedTableRows: PerformanceNestedTableRowData[],
  accountGroupData?: PerformanceByAccountGroupModel[]
): PerformanceTableRowData => {
  const performanceData: PerformanceByAccountGroupModel | undefined =
    accountGroupData?.find(
      (performanceData) => accountGroup.id === performanceData.accountGroupId
    );

  let performanceTableRowData: PerformanceTableRowData = {
    accountGroupId: accountGroup.id,
    name: accountGroup.name,
    asOfDate: performanceData?.asOfDate ?? undefined,
    marketValue: performanceData?.marketValue,
    monthToDate: performanceData?.monthToDate,
    quarterToDate: performanceData?.quarterToDate,
    yearToDate: performanceData?.yearToDate,
    oneYearReturns: performanceData?.oneYearReturns,
    threeYearReturns: performanceData?.threeYearReturns,
    fiveYearReturns: performanceData?.fiveYearReturns,
    tenYearReturns: performanceData?.tenYearReturns,
    sinceInception: performanceData?.sinceInception,
    inceptionDate: performanceData?.inceptionDate,
    nestedData: nestedTableRows.map((nestedTableRow) => {
      return {
        accountId: nestedTableRow.accountId,
        custodian: nestedTableRow.custodian,
        name: nestedTableRow.name,
        marketValue: nestedTableRow.marketValue,
        monthToDate: nestedTableRow.monthToDate,
        quarterToDate: nestedTableRow.quarterToDate,
        yearToDate: nestedTableRow.yearToDate,
        oneYearReturns: nestedTableRow.oneYearReturns,
        threeYearReturns: nestedTableRow.threeYearReturns,
        fiveYearReturns: nestedTableRow.fiveYearReturns,
        tenYearReturns: nestedTableRow.tenYearReturns,
        sinceInception: nestedTableRow.sinceInception,
        inceptionDate: nestedTableRow.inceptionDate,
        netGross: '',
      } as PerformanceNestedTableRowData;
    }),
  };

  return performanceTableRowData;
};

const mapTableRows = (
  accountGroups: UserProfileAccountGroup[],
  nestedTableRows: PerformanceNestedTableRowData[],
  accountGroupData: PerformanceByAccountGroupModel[]
): PerformanceTableRowData[] => {
  let ungroupedPerformanceData = nestedTableRows;
  let performanceDataForAccountGroup = [];

  let performanceTableRows: PerformanceTableRowData[] = accountGroups.map(
    (accountGroup) => {
      [performanceDataForAccountGroup, ungroupedPerformanceData] = _.partition(
        ungroupedPerformanceData,
        (nestedPerformanceData) =>
          accountGroup.accounts.some(
            (account) =>
              account.financialAccountId === nestedPerformanceData.accountId
          )
      );
      return mapTableRow(
        accountGroup,
        performanceDataForAccountGroup,
        accountGroupData
      );
    }
  );

  if (ungroupedPerformanceData.length > 0) {
    performanceTableRows.push(
      mapTableRow(
        {
          id: '0',
          name: 'Ungrouped Accounts',
          accounts: [],
        },
        ungroupedPerformanceData
      )
    );
  }

  return performanceTableRows.filter(
    (accountGroup) =>
      accountGroup.nestedData && accountGroup?.nestedData.length > 0
  );
};

export const projectPerformanceByAccountGroupData = (
  accounts: ClientPortalFinancialAccount[],
  accountGroups: UserProfileAccountGroup[],
  accountData: PerformanceByAccountModel[],
  accountGroupData: PerformanceByAccountGroupModel[]
): PerformanceTableRowData[] => {
  const performanceDataWithNicknames = accountData.map((o) => {
    const nickname = accounts.find(
      (account) => account.nicknameEntry?.financialAccountId === o.accountId
    )?.nicknameEntry?.accountNickname;
    return {
      ...o,
      name: nickname ? nickname : o.accountName,
    } as PerformanceNestedTableRowData;
  });
  return mapTableRows(
    accountGroups,
    performanceDataWithNicknames ?? [],
    accountGroupData
  );
};

export const projectPerformanceByAssetClassData = (
  assetClassData: PerformanceByAssetClassModel[],
  productData: PerformanceByProductModel[],
  householdData: HouseholdPerformanceModel[]
): PerformanceTableRowData[] => {
  return householdData.map((householdItem) => {
    const assetClassDataForHousehold = assetClassData.filter(
      (o) => o.householdId === householdItem.householdId
    );
    return {
      ...householdItem,
      name: householdItem.householdName,
      uniqueId: householdItem.householdId,
      nestedData: assetClassDataForHousehold.map((assetClassItem) => {
        const productDataForAssetClass = productData.filter(
          (o) =>
            o.assetClassId === assetClassItem.assetClassId &&
            o.householdId === assetClassItem.householdId
        );
        return { 
          name: assetClassItem.assetClassName,
          uniqueId: assetClassItem.assetClassId,
          ...assetClassItem,
          nestedData: productDataForAssetClass.map((productItem) => {
            return {    
              name: productItem.productName ? productItem.productName : '',
              uniqueId: productItem.productId,
              ...productItem,
            };
          }),
        };
      }),
    };
  });
};

export const projectPerformanceBySubAssetClassData = (
  subAssetClassData: PerformanceBySubAssetClassModel[],
  productData: PerformanceByProductModel[],
  householdData: HouseholdPerformanceModel[]
): PerformanceTableRowData[] => {
  return householdData.map((householdItem) => {
    const subAssetClassDataForHousehold = subAssetClassData.filter(
      (o) => o.householdId === householdItem.householdId
    );
    return {
      ...householdItem,
      name: householdItem.householdName,
      uniqueId: householdItem.householdId,
      nestedData: subAssetClassDataForHousehold.map((subAssetClassItem) => {
        const productDataForSubAssetClass = productData.filter(
          (o) =>
            o.subAssetClassId === subAssetClassItem.subAssetClassId &&
            o.householdId === subAssetClassItem.householdId
        );
        return {
          name: subAssetClassItem.subAssetClassName,
          uniqueId: subAssetClassItem.subAssetClassId,
          ...subAssetClassItem,
          nestedData: productDataForSubAssetClass.map((productItem) => {
            return {
              name: productItem.productName ? productItem.productName : '',
              uniqueId: productItem.productId,
              ...productItem,
            };
          }),
        };
      }),
    };
  });
};

export const projectPerformanceByHoldingsData = (
  accountData: PerformanceByHoldingsModel[],
  productData: PerformanceByProductModel[],
  householdData: HouseholdPerformanceModel[]
): PerformanceTableRowData[] => {
  return householdData.map((householdItem) => {
    const productDataForHousehold = productData.filter(
      (o) => o.householdId === householdItem.householdId
    );
    return {
      ...householdItem,
      name: householdItem.householdName,
      uniqueId: householdItem.householdId,
      nestedData: productDataForHousehold.map((holdingsItem) => {
        const accountDataForHoldings = accountData.filter(
          (o) =>
            o.productId === holdingsItem.productId &&
            o.householdId === holdingsItem.householdId
        );
        return {
          name: holdingsItem.productName ? holdingsItem.productName : '',
          uniqueId: holdingsItem.productId,
          ...holdingsItem,
          nestedData: accountDataForHoldings.map((accountItem) => {
            return {
              ...accountItem,
              name: accountItem.accountName ? accountItem.accountName : '',
              uniqueId: accountItem.accountId,
            };
          }),
        };
      }),
    };
  });
};

export const projectPerformanceByManagementStyleData = (
  accountData: PerformanceByManagementStyleModel[],
  managementStyleData: PerformanceByManagementStyleModel[],
  householdData: HouseholdPerformanceModel[]
): HouseholdTableRowData[] => {
  return householdData.map((householdItem) => {
    const mgmtStyleDataForHousehold = managementStyleData.filter(
      (o) => o.householdId === householdItem.householdId
    );
    return {    
      ...householdItem,
      name: householdItem.householdName,
      uniqueId: householdItem.householdId,
      nestedData: mgmtStyleDataForHousehold.map((mgmtStyleItem) => {
        const accountDataForManagementStyle = accountData.filter(
          (o) =>
            o.managementStyleId === mgmtStyleItem.managementStyleId &&
            o.householdId === mgmtStyleItem.householdId
        );
        return {      
          ...mgmtStyleItem,
          name: mgmtStyleItem.managementStyle,
          uniqueId: mgmtStyleItem.managementStyleId,
          nestedData: accountDataForManagementStyle.map((accountItem) => {
            return {
              ...accountItem,
            };
          }),
        };
      }),
    };
  });
};
