import {
  ClientPortalFinancialAccount,
  UserProfileAccountGroup,
  UserProfileFinancialAccountNickname,
} from '@newedge/common';
import { InternalFinancialAccountInfo } from '../@types/global';

export const combineUserProfileAndClientSummaryFinancialAccountInfo = (
  userProfileAccounts: UserProfileFinancialAccountNickname[] | null,
  userProfileAccountGroups: UserProfileAccountGroup[] | null,
  clientSummaryAccounts: InternalFinancialAccountInfo[] | null
): ClientPortalFinancialAccount[] => {
  if (userProfileAccounts === null || clientSummaryAccounts === null)
    return [] as ClientPortalFinancialAccount[];
  return clientSummaryAccounts?.map(
    (clientSummaryFinancialAccount: InternalFinancialAccountInfo) => {
      const userProfileAccountInfo = userProfileAccounts?.find(
        (userProfileAccount) => {
          return (
            userProfileAccount.financialAccountId ===
            clientSummaryFinancialAccount.accountId
          );
        }
      );

      const accountGroup: UserProfileAccountGroup | undefined =
        userProfileAccountGroups?.find((x) =>
          x.accounts.some(
            (y) =>
              y.financialAccountId === clientSummaryFinancialAccount.accountId
          )
        );
      const accountName =
        userProfileAccountInfo?.accountNickname ??
        clientSummaryFinancialAccount.accountDefaultName;
      return {
        financialAccountId: clientSummaryFinancialAccount.accountId,
        accountName,
        defaultAccountName: clientSummaryFinancialAccount.accountDefaultName,
        nicknameEntry: userProfileAccountInfo,
        accountNumber: clientSummaryFinancialAccount.accountNumber,
        managementStyle: clientSummaryFinancialAccount.managementStyle,
        accountType: clientSummaryFinancialAccount.accountType,
        accountGroupEntry: accountGroup,
        accountBalance: clientSummaryFinancialAccount.totalBalance,
        custodian: clientSummaryFinancialAccount.custodian,
      };
    }
  );
};

export default combineUserProfileAndClientSummaryFinancialAccountInfo;
