import {
  Box,
  Button,
  Checkbox,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { ColumnDataType, Order } from '../../sortable-table/@types';
import { Key, useState } from 'react';
import PanelSortLabel from './PanelSortLabel';
import { TooltipWrapper } from '@newedge/common';

interface TransferSelectionPanelProps<T> {
  title: string;
  buttonTitle: string;
  buttonColor: 'primary' | 'secondary';
  onButtonClick: (selected: T[]) => void;
  data: T[];
  metadata: {
    id: keyof T;
    displayValue: keyof T;
    label: string;
    dataType: ColumnDataType;
  };
}

export const TransferSelectionPanel = <T,>({
  title,
  buttonTitle,
  buttonColor,
  onButtonClick,
  data,
  metadata,
}: TransferSelectionPanelProps<T>) => {
  const [checkedData, setCheckedData] = useState<T[]>([]);
  const [sortDirection, setSortDirection] = useState<Order>(Order.Asc);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mx: 1,
      }}
    >
      <Box
        sx={(theme) => ({
          alignSelf: 'start',
          paddingBottom: theme.spacing(2),
          ...theme.typography.h5,
          fontWeight: 'bold',
        })}
      >
        {title}
      </Box>
      <Box
        sx={{
          border: '1px solid #717171',
          marginBottom: 2,
          height: '350px',
          overflowY: 'scroll',
          minWidth: '250px',
        }}
      >
        <PanelSortLabel
          label={metadata.label}
          sortDirection={sortDirection}
          onClick={() => {
            if (sortDirection === Order.Asc) {
              setSortDirection(Order.Desc);
            } else {
              setSortDirection(Order.Asc);
            }
          }}
        />
        <List dense component='div' role='list'>
          {data
            .sort((a, b) => {
              const result = (b[metadata.displayValue] as string).localeCompare(
                a[metadata.displayValue] as string
              );
              if (sortDirection === Order.Asc) {
                return -result;
              }
              return result;
            })
            .map((item, index) => {
              return (
                <ListItemButton
                  key={item as Key}
                  onClick={() => {
                    const checkedIndex = checkedData.findIndex(
                      (o) => o[metadata.id] === item[metadata.id]
                    );
                    if (checkedIndex !== -1) {
                      const newData = checkedData.slice();
                      newData.splice(checkedIndex, 1);
                      setCheckedData(newData);
                    } else {
                      setCheckedData([...checkedData, item]);
                    }
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge='start'
                      checked={
                        checkedData.findIndex(
                          (o) => o[metadata.id] === item[metadata.id]
                        ) !== -1
                      }
                      disableRipple
                      inputProps={{
                        'aria-labelledby': item[metadata.displayValue] as string,
                      }}
                      color='iron'
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={item[metadata.displayValue] as string}
                    disableTypography
                  >
                    <TooltipWrapper
                      text={item[metadata.displayValue] as string}
                      maxLength={72}
                      typographyVariant='body1'
                    />
                  </ListItemText>
                </ListItemButton>
              );
            })}
        </List>
      </Box>
      <Button
        color={buttonColor}
        onClick={() => {
          onButtonClick(checkedData);
          setCheckedData([]);
        }}
      >
        {buttonTitle}
      </Button>
    </Box>
  );
};

export default TransferSelectionPanel;
