import { createContext, ReactNode, useCallback, useState } from 'react';

export interface GlobalFilterContextType {
  selectedAccounts: number[];
  setSelectedAccounts: (accounts: number[]) => void;
  allAccounts: number[];
  setAllAccounts: (accounts: number[]) => void;
  clientId: number | undefined;
  setClientId: (clientId: number | undefined) => void;
}

export const GlobalFilterContext = createContext({} as GlobalFilterContextType);

interface GlobalFilterProviderProps {
  children: ReactNode;
}

export const GlobalFilterProvider = ({
  children,
}: GlobalFilterProviderProps) => {
  const [selectedAccounts, setSelectedAccounts] = useState<number[]>([]);
  const [allAccounts, setAllAccounts] = useState<number[]>([]);
  const [clientId, setClientId] = useState<number | undefined>();

  const getContextValue = useCallback(() => {
    return {
      selectedAccounts,
      setSelectedAccounts,
      allAccounts,
      setAllAccounts,
      clientId,
      setClientId,
    };
  }, [allAccounts, selectedAccounts, clientId]);

  return (
    <GlobalFilterContext.Provider value={getContextValue()}>
      {children}
    </GlobalFilterContext.Provider>
  );
};
