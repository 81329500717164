import { useContext, useMemo, useState } from 'react';
import { ReportPreviewWrapper } from '../ReportPreviewWrapper';
import { HoldingsDetailModel, HoldingsDetailView } from '@newedge/reports';
import {
  FilterItem,
  UserProfileAccountGroup,
  UserProfileFinancialAccountNickname,
  findNicknameForAccountId,
} from '@newedge/common';
import { GlobalFilterContext } from '../../../components/context/GlobalFilterContext';
import graphql from 'babel-plugin-relay/macro';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import combineUserProfileAndClientSummaryFinancialAccountInfo from '../../../models/InternalPortalFinancialAccount';
import { InternalFinancialAccountInfo } from '../../../@types/global';
import { HoldingsPreviewQueryReaderQuery } from './__generated__/HoldingsPreviewQueryReaderQuery.graphql';

export const HoldingsPreviewQueryReaderQueryNode = graphql`
  query HoldingsPreviewQueryReaderQuery(
    $input: GetClientUserInput!
    $clientWhere: ClientModelFilterInput
    $holdingsInput: GetHoldingsDetailInput!
    $holdingsWhere: HoldingsDetailModelFilterInput
  ) {
    userprofile_viewer {
      clientUser(input: $input) {
        id
        accounts {
          accountNickname
          financialAccountId
        }
        accountGroups {
          id
          name
          accounts {
            id
            financialAccountId
          }
        }
      }
    }
    clientsummary_viewer {
      client(where: $clientWhere) {
        accounts {
          accountDefaultName
          accountId
          accountNumber
          managementStyle
          custodian
          totalBalance
        }
        userId
        getHoldingsDetail(input: $holdingsInput, where: $holdingsWhere) {
          accountId
          asOfDate
          assetClass
          costBasis
          currentMarketValue
          holdingsId
          managementStyle
          price
          productName
          quantity
          subAssetClass
          ticker
          unrealizedGain
          unrealizedGainPercent
          yield
        }
      }
    }
  }
`;

interface HoldingsPreviewQueryReaderProps {
  queryRef: PreloadedQuery<HoldingsPreviewQueryReaderQuery>;
  filterValues: FilterItem[];
  setFilterValues: (filterValues: FilterItem[]) => void;
  hasInitialFilters: boolean;
}

export const HoldingsPreviewQueryReader = ({
  queryRef,
  filterValues,
  setFilterValues,
  hasInitialFilters,
}: HoldingsPreviewQueryReaderProps) => {
  const data = usePreloadedQuery(HoldingsPreviewQueryReaderQueryNode, queryRef);
  const [asOfDate, setAsOfDate] = useState<string | null>(null);
  const clientUserProfile = data.userprofile_viewer?.clientUser[0];
  const clientSummary = data.clientsummary_viewer.client.find(
    (o) => o.userId === clientUserProfile?.id
  );

  const date = clientSummary?.getHoldingsDetail?.[0]?.asOfDate;

  const accountGroups =
    clientUserProfile?.accountGroups as UserProfileAccountGroup[];

  const accounts = combineUserProfileAndClientSummaryFinancialAccountInfo(
    clientUserProfile?.accounts as UserProfileFinancialAccountNickname[],
    accountGroups,
    clientSummary?.accounts as InternalFinancialAccountInfo[]
  );

  const holdingsData = useMemo(
    () =>
      (clientSummary?.getHoldingsDetail as HoldingsDetailModel[])?.map(
        (holding) => {
          return {
            ...holding,
            accountNickname: findNicknameForAccountId(
              accounts,
              holding?.accountId
            ),
          } as HoldingsDetailModel;
        }
      ),
    [accounts, clientSummary?.getHoldingsDetail]
  );
  const { selectedAccounts } = useContext(GlobalFilterContext);

  return (
    <ReportPreviewWrapper asOfDate={asOfDate}>
      <HoldingsDetailView
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        setAsOfDate={setAsOfDate}
        date={date}
        accounts={accounts}
        selectedAccounts={selectedAccounts}
        holdingsData={holdingsData}
        hasInitialFilters={hasInitialFilters}
      />
    </ReportPreviewWrapper>
  );
};
