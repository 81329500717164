/**
 * @generated SignedSource<<6faebd6fdd8c52acea9bfe44842504f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ClientAccountGroupAccountChangesInput = {
  accountGroupId: any;
  accounts: ReadonlyArray<AccountInput>;
  clientId: any;
};
export type AccountInput = {
  financialAccountId: number;
  id: any;
};
export type AccountGroupAccordion_RemoveAccountsFromClientAccountGroupMutation$variables = {
  input: ClientAccountGroupAccountChangesInput;
};
export type AccountGroupAccordion_RemoveAccountsFromClientAccountGroupMutation$data = {
  readonly RemoveAccountsFromClientAccountGroup: {
    readonly accountGroup: {
      readonly accounts: ReadonlyArray<{
        readonly financialAccountId: number;
        readonly id: any;
      }>;
    } | null;
  };
};
export type AccountGroupAccordion_RemoveAccountsFromClientAccountGroupMutation = {
  response: AccountGroupAccordion_RemoveAccountsFromClientAccountGroupMutation$data;
  variables: AccountGroupAccordion_RemoveAccountsFromClientAccountGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Account",
  "kind": "LinkedField",
  "name": "accounts",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "financialAccountId",
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountGroupAccordion_RemoveAccountsFromClientAccountGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ClientUserPayload",
        "kind": "LinkedField",
        "name": "RemoveAccountsFromClientAccountGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountGroup",
            "kind": "LinkedField",
            "name": "accountGroup",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountGroupAccordion_RemoveAccountsFromClientAccountGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ClientUserPayload",
        "kind": "LinkedField",
        "name": "RemoveAccountsFromClientAccountGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountGroup",
            "kind": "LinkedField",
            "name": "accountGroup",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "95b400f9d92b443a147943f3010830bb",
    "id": null,
    "metadata": {},
    "name": "AccountGroupAccordion_RemoveAccountsFromClientAccountGroupMutation",
    "operationKind": "mutation",
    "text": "mutation AccountGroupAccordion_RemoveAccountsFromClientAccountGroupMutation(\n  $input: ClientAccountGroupAccountChangesInput!\n) {\n  RemoveAccountsFromClientAccountGroup(input: $input) {\n    accountGroup {\n      accounts {\n        financialAccountId\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3d1d0b1f9fe6dfaa0857dd3f05b4b3fa";

export default node;
