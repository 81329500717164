import { Box } from '@mui/material';
import { ClientPortalLogo } from '@newedge/common';
import WelcomeHeader from './WelcomeHeader';
import TopBar from './Topbar';
import BackgroundPng from '../../../assets/img/bg-img.png';

interface HeaderProps {
  displayName: string;
}

export const Header = ({ displayName }: HeaderProps) => {
  return (
    <>
      <TopBar userDisplayName={displayName} />
      <Box
        sx={{
          justifyContent: 'left',
          width: '17%',
          maxWidth: '1400px',
          paddingBottom: '2rem',
        }}
      >
        <ClientPortalLogo />
      </Box>
      <Box
        sx={(theme) => ({
          width: '100%',
          maxWidth: '1400px',
          [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            overflow: 'hidden',
            width: '100%',
          },
          paddingBottom: '2rem',
        })}
      >
        <WelcomeHeader userDisplayName={displayName} />
      </Box>
      <Box
        component='img'
        sx={{
          position: 'fixed',
          top: '100px',
          left: '50%',
          transform: ' translate(-50%, 0)',
          zIndex: -1,
          height: '100vh',
        }}
        src={`${BackgroundPng}`}
        alt=''
      />
    </>
  );
};
