import {
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEvent,
  ChangeEvent,
  FormEvent,
} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  InputBase,
  Typography,
} from '@mui/material';
import SortableTable from '../sortable-table/SortableTable';
import { ColumnDataType, HeadCell } from '../sortable-table/@types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TransferListModal from '../modals/transfer-list/TransferListModal';
import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';
import {
  AccountGroupAccordion_AddAccountsToClientAccountGroupMutation,
  AccountInput,
  ClientAccountGroupAccountChangesInput,
} from './__generated__/AccountGroupAccordion_AddAccountsToClientAccountGroupMutation.graphql';
import { AccountGroupAccordion_RemoveAccountsFromClientAccountGroupMutation } from './__generated__/AccountGroupAccordion_RemoveAccountsFromClientAccountGroupMutation.graphql';
import {
  AccountGroupAccordion_DeactivateClientAccountGroupMutation,
  DeleteClientAccountGroupInput,
} from './__generated__/AccountGroupAccordion_DeactivateClientAccountGroupMutation.graphql';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { convertRelayIdToIdentifier } from '@newedge/relay';
import {
  ClientPortalFinancialAccount,
  ConfirmDeleteDialog,
  UserProfileAccountGroup,
  validateAccountGroupName,
} from '@newedge/common';
import { newEdgeNamedColors } from '@newedge/theme';
import {
  AccountGroupAccordion_UpdateClientAccountGroupNameMutation,
  ClientAccountGroupNameChangeInput,
} from './__generated__/AccountGroupAccordion_UpdateClientAccountGroupNameMutation.graphql';

export const AddAccountsToClientAccountGroup = graphql`
  mutation AccountGroupAccordion_AddAccountsToClientAccountGroupMutation(
    $input: ClientAccountGroupAccountChangesInput!
  ) {
    AddAccountsToClientAccountGroup(input: $input) {
      accountGroup {
        accounts {
          financialAccountId
          id
        }
        id
        name
      }
    }
  }
`;

export const RemoveAccountsFromClientAccountGroup = graphql`
  mutation AccountGroupAccordion_RemoveAccountsFromClientAccountGroupMutation(
    $input: ClientAccountGroupAccountChangesInput!
  ) {
    RemoveAccountsFromClientAccountGroup(input: $input) {
      accountGroup {
        accounts {
          financialAccountId
          id
        }
      }
    }
  }
`;

export const DeactivateClientAccountGroup = graphql`
  mutation AccountGroupAccordion_DeactivateClientAccountGroupMutation(
    $input: DeleteClientAccountGroupInput!
  ) {
    DeactivateClientAccountGroup(input: $input) {
      accountGroup {
        accounts {
          financialAccountId
          id
        }
        id
        name
      }
    }
  }
`;

export const UpdateClientAccountGroupName = graphql`
  mutation AccountGroupAccordion_UpdateClientAccountGroupNameMutation(
    $input: ClientAccountGroupNameChangeInput!
  ) {
    UpdateClientAccountGroupName(input: $input) {
      accountGroup {
        id
        name
        accounts {
          id
          financialAccountId
        }
      }
      errors {
        message
        code
      }
    }
  }
`;

interface AccountGroupAccordionProps {
  name: string;
  userProfileInfo?: UserProfileAccountGroup;
  accounts: ClientPortalFinancialAccount[];
  unfilteredAccounts: ClientPortalFinancialAccount[];
  availableAccounts?: ClientPortalFinancialAccount[];
  accountGroupNames?: string[];
}

const detailTableMetadata: HeadCell<ClientPortalFinancialAccount>[] = [
  {
    id: 'accountName',
    dataType: ColumnDataType.String,
    label: 'Account Nickname',
    maxLength: 72,
  },
  {
    id: 'accountNumber',
    dataType: ColumnDataType.String,
    label: 'Account Number',
  },
  {
    id: 'accountBalance',
    dataType: ColumnDataType.Currency,
    label: 'Account Balance',
  },
];

export const AccountGroupAccordion = ({
  name,
  userProfileInfo,
  accounts,
  unfilteredAccounts,
  availableAccounts,
  accountGroupNames,
}: AccountGroupAccordionProps) => {
  const accountGroupId = userProfileInfo?.id;
  const { clientUserId: clientId = '' } = useParams();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
  const [accountGroupName, setAccountGroupName] = useState<string>(name);
  const inputRef = useRef<HTMLInputElement>(null);

  const update = () => {
    if (accountGroupId && !errorMessage) {
      updateClientAccountGroupName(accountGroupId, accountGroupName);
      setIsInEditMode(false);
    }
  };

  const handleEditClick = (event: MouseEvent<HTMLElement>) => {
    setIsInEditMode(true);
    setAccountGroupName(name);

    if (inputRef.current) {
      inputRef.current.focus();
    }

    event.stopPropagation();
  };

  const handleExpandClick = (event: any) => {
    setExpanded(!expanded);
    setIsInEditMode(false);
    setErrorMessage('');
    event.stopPropagation();
  };

  const handleUpdateClick = (event: MouseEvent<HTMLElement>) => {
    update();
    event.stopPropagation();
  };

  const handleInputClick = (event: MouseEvent<HTMLElement>) => {
    if (isInEditMode) {
      event.stopPropagation();
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAccountGroupName(event.target.value);

    let errorMessage = validateAccountGroupName(
      event.target.value,
      accountGroupNames,
      name
    );

    setErrorMessage(errorMessage);
  };

  const handleSubmit = (event: FormEvent) => {
    if (isInEditMode) {
      update();
    }

    event.preventDefault();
    event.stopPropagation();
  };

  const [commitAddAccountsToClientAccountGroup] =
    useMutation<AccountGroupAccordion_AddAccountsToClientAccountGroupMutation>(
      AddAccountsToClientAccountGroup
    );

  const addAccountsToAccountGroup = useCallback(
    (financialAccountIds: number[]) => {
      if (accountGroupId) {
        const convertedAccountGroupId = convertRelayIdToIdentifier(
          'AccountGroup',
          accountGroupId
        );
        let accountsToAdd = [] as AccountInput[];
        financialAccountIds.forEach((id) => {
          accountsToAdd.push({
            id: uuid(),
            financialAccountId: id,
          });
        });
        const input: ClientAccountGroupAccountChangesInput = {
          accountGroupId: convertedAccountGroupId,
          accounts: accountsToAdd,
          clientId,
        };
        commitAddAccountsToClientAccountGroup({
          variables: {
            input,
          },
        });
      }
    },
    [accountGroupId, clientId, commitAddAccountsToClientAccountGroup]
  );

  const [commitRemoveAccountsFromClientAccountGroup] =
    useMutation<AccountGroupAccordion_RemoveAccountsFromClientAccountGroupMutation>(
      RemoveAccountsFromClientAccountGroup
    );

  const removeAccountsFromAccountGroup = useCallback(
    (financialAccountIds: number[]) => {
      if (accountGroupId) {
        const convertedAccountGroupId = convertRelayIdToIdentifier(
          'AccountGroup',
          accountGroupId
        );
        let accountsToRemove = [] as AccountInput[];
        financialAccountIds.forEach((id) => {
          const acct = userProfileInfo?.accounts.find(
            (acct) => acct.financialAccountId === id
          );
          if (acct) {
            accountsToRemove.push(acct);
          }
        });
        const input: ClientAccountGroupAccountChangesInput = {
          accountGroupId: convertedAccountGroupId,
          accounts: accountsToRemove,
          clientId,
        };
        commitRemoveAccountsFromClientAccountGroup({
          variables: {
            input,
          },
        });
      }
    },
    [
      accountGroupId,
      userProfileInfo,
      clientId,
      commitRemoveAccountsFromClientAccountGroup,
    ]
  );

  const [commitDeactivateClientAccountGroup] =
    useMutation<AccountGroupAccordion_DeactivateClientAccountGroupMutation>(
      DeactivateClientAccountGroup
    );

  const deactivateClientAccountGroup = useCallback(() => {
    if (accountGroupId) {
      const convertedAccountGroupId = convertRelayIdToIdentifier(
        'AccountGroup',
        accountGroupId
      );
      const input: DeleteClientAccountGroupInput = {
        accountGroupId: convertedAccountGroupId,
        clientId,
      };
      commitDeactivateClientAccountGroup({
        variables: {
          input,
        },
        updater: (store) => {
          const root = store.getRoot();
          const userProfileViewer = root.getLinkedRecord('userprofile_viewer');
          const clientUser = userProfileViewer?.getLinkedRecords(
            `clientUser(input:{"clientUserIds":["${clientId}"]})`
          );
          if (clientUser && clientUser[0]) {
            const accountGroups =
              clientUser[0].getLinkedRecords('accountGroups');
            const removedAccountGroup = store.get(accountGroupId);
            if (accountGroups !== null) {
              const updatedAccountGroups = accountGroups.filter(
                (accountGroup) =>
                  accountGroup.getDataID() !== removedAccountGroup!.getDataID()
              );
              clientUser[0].setLinkedRecords(
                updatedAccountGroups,
                'accountGroups'
              );
              store.delete(removedAccountGroup!.getDataID());
            }
          }
        },
      });
    }
  }, [accountGroupId, clientId, commitDeactivateClientAccountGroup]);

  const [commitUpdateClientAccountGroupName] =
    useMutation<AccountGroupAccordion_UpdateClientAccountGroupNameMutation>(
      UpdateClientAccountGroupName
    );

  const updateClientAccountGroupName: any = useCallback(
    async (accountGroupId: any, newName: string) => {
      if (accountGroupId) {
        const convertedAccountGroupId = convertRelayIdToIdentifier(
          'AccountGroup',
          accountGroupId
        );

        const input: ClientAccountGroupNameChangeInput = {
          accountGroupId: convertedAccountGroupId,
          newName: newName,
          clientId: clientId,
        };

        commitUpdateClientAccountGroupName({
          variables: {
            input,
          },
        });
      }
    },
    [accountGroupId, clientId, commitUpdateClientAccountGroupName]
  );

  const [expanded, setExpanded] = useState(false);
  const [transferModalOpen, setTransferModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [assignedAccounts, setAssignedAccounts] = useState(unfilteredAccounts);
  const [unassignedAccounts, setUnassignedAccounts] =
    useState(availableAccounts);

  useEffect(() => {
    setAssignedAccounts(unfilteredAccounts);
    setUnassignedAccounts(availableAccounts);
  }, [unfilteredAccounts, availableAccounts]);

  const [searchValue, setSearchValue] = useState<string>('');

  const applySearch = useCallback(
    (data: ClientPortalFinancialAccount[]): ClientPortalFinancialAccount[] => {
      return data.filter((acct) => {
        if (searchValue.length > 0) {
          return (
            acct.accountName.toLowerCase().indexOf(searchValue.toLowerCase()) >
            -1
          );
        }
        return true;
      });
    },
    [searchValue]
  );

  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={handleExpandClick}
        disableGutters
        sx={(theme) => ({
          backgroundColor: theme.extensions.filter[24],
          '&:last-of-type': {
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
          },
        })}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ fontSize: '4rem' }} />}
          sx={(theme) => {
            return {
              flexDirection: 'row-reverse',
              '& .MuiAccordionSummary-content': {
                alignItems: 'center',
                justifyContent: 'space-between',
                my: 0.5,
                minHeight: '76px',
              },
            };
          }}
        >
          <Box
            component='form'
            sx={{ display: 'flex', alignItems: 'center' }}
            onSubmit={handleSubmit}
          >
            <Typography
              variant='h3'
              sx={{ display: isInEditMode ? 'none' : 'block' }}
            >
              {name}
            </Typography>
            <InputBase
              value={accountGroupName}
              readOnly={!isInEditMode}
              inputRef={inputRef}
              inputProps={{
                sx: {
                  width: '210px',
                  borderRadius: isInEditMode ? 4 : undefined,
                  border: isInEditMode
                    ? `0.5px solid ${newEdgeNamedColors.lightCadet}`
                    : undefined,
                  display: isInEditMode ? 'block' : 'none',
                },
                onClick: handleInputClick,
                onChange: handleInputChange,
              }}
            />
            {name !== 'View Ungrouped Accounts' && (
              <Button
                id={accountGroupId}
                sx={{
                  backgroundColor: newEdgeNamedColors.cadet,
                  visibility: expanded ? undefined : 'hidden',
                }}
                onClick={
                  isInEditMode && accountGroupName.length > 0
                    ? handleUpdateClick
                    : handleEditClick
                }
                key={accountGroupId + '-edit'}
              >
                {isInEditMode && accountGroupName.length > 0
                  ? 'Update'
                  : 'Edit'}
              </Button>
            )}

            {errorMessage && (
              <Typography
                sx={{
                  position: 'absolute',
                  bottom: '0',
                  width: '100%',
                  color: '#f44336',
                  display: isInEditMode ? 'block' : 'none',
                }}
              >
                {errorMessage}
              </Typography>
            )}
          </Box>

          {availableAccounts ? (
            <Box>
              <Button
                onClick={(e) => {
                  setDeleteModalOpen(true);
                  e.stopPropagation();
                }}
                color='secondary'
                sx={(theme) => ({
                  backgroundColor: theme.palette.greyPercent[60],
                  color: '#000000',
                })}
              >
                Delete
              </Button>
              <Button
                onClick={(e) => {
                  setTransferModalOpen(true);
                  e.stopPropagation();
                }}
              >
                Add Accounts
              </Button>
            </Box>
          ) : null}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 'unset' }}>
          <SortableTable
            headCells={detailTableMetadata}
            dataRows={accounts}
            initialSortColumn={'accountName'}
            actionRenderer={
              availableAccounts
                ? (row) => {
                    return (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'end',
                        }}
                      >
                        <Button
                          onClick={() => {
                            removeAccountsFromAccountGroup([
                              row.financialAccountId,
                            ]);
                          }}
                          variant='text'
                          sx={{ color: '#F0F0F0', padding: 1, margin: 0 }}
                        >
                          Remove
                        </Button>
                      </Box>
                    );
                  }
                : undefined
            }
          />
        </AccordionDetails>
      </Accordion>
      {unassignedAccounts ? (
        <>
          <ConfirmDeleteDialog
            open={deleteModalOpen}
            setOpen={setDeleteModalOpen}
            message={
              <>
                <div>
                  This Account Group has {assignedAccounts.length} accounts
                  assigned to it. Once deleted, these accounts will be
                  ungrouped.
                </div>
                <div>Are you sure you want to delete this Account Group?</div>
              </>
            }
            submit={() => deactivateClientAccountGroup()}
          />
          <TransferListModal
            open={transferModalOpen}
            close={() => {
              setTransferModalOpen(false);
              setSearchValue('');
            }}
            parentTitle={`Add Accounts to ${name}`}
            leftTitle='Accounts'
            rightTitle='Accounts Added'
            addButtonTitle='Add Accounts'
            removeButtonTitle='Remove Accounts'
            onAddButtonClick={(selectedAccounts) => {
              setAssignedAccounts([...assignedAccounts, ...selectedAccounts]);
              setUnassignedAccounts([
                ...unassignedAccounts.filter(
                  (o) =>
                    selectedAccounts.findIndex(
                      (x) => x.accountNumber === o.accountNumber
                    ) === -1
                ),
              ]);
            }}
            onRemoveButtonClick={(selectedAccounts) => {
              setUnassignedAccounts([
                ...unassignedAccounts,
                ...selectedAccounts,
              ]);
              setAssignedAccounts([
                ...assignedAccounts.filter(
                  (o) =>
                    selectedAccounts.findIndex(
                      (x) => x.accountNumber === o.accountNumber
                    ) === -1
                ),
              ]);
            }}
            leftData={applySearch(unassignedAccounts)}
            rightData={applySearch(assignedAccounts)}
            metadata={{
              id: 'accountNumber',
              displayValue: 'accountName',
              label: 'Account Name',
              dataType: ColumnDataType.String,
            }}
            onSave={() => {
              const addedAccounts = assignedAccounts.filter(
                (acct) =>
                  accounts.findIndex(
                    (o) => o.financialAccountId === acct.financialAccountId
                  ) === -1
              );
              const removedAccounts = unassignedAccounts.filter(
                (acct) =>
                  availableAccounts?.findIndex(
                    (o) => o.financialAccountId === acct.financialAccountId
                  ) === -1
              );
              addAccountsToAccountGroup(
                addedAccounts.map((acct) => acct.financialAccountId)
              );
              removeAccountsFromAccountGroup(
                removedAccounts.map((acct) => acct.financialAccountId)
              );
            }}
            onCancel={() => {
              setAssignedAccounts(unfilteredAccounts);
              setUnassignedAccounts(availableAccounts);
            }}
            onSearch={(value) => {
              setSearchValue(value);
            }}
          />
        </>
      ) : null}
    </>
  );
};

export default AccountGroupAccordion;
