import {
  FilterItem,
  UserProfileAccountGroup,
  UserProfileFinancialAccountNickname,
} from '@newedge/common';
import graphql from 'babel-plugin-relay/macro';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { AssetAllocationPreviewQueryReaderQuery } from './__generated__/AssetAllocationPreviewQueryReaderQuery.graphql';
import { AssetAllocationDetailView } from '@newedge/reports';
import { useContext, useState } from 'react';
import { ReportPreviewWrapper } from '../ReportPreviewWrapper';
import combineUserProfileAndClientSummaryFinancialAccountInfo from '../../../models/InternalPortalFinancialAccount';
import { InternalFinancialAccountInfo } from '../../../@types/global';
import { GlobalFilterContext } from '../../../components/context/GlobalFilterContext';

export const AssetAllocationPreviewQueryReaderQueryNode = graphql`
  query AssetAllocationPreviewQueryReaderQuery(
    $input: GetClientUserInput!
    $clientWhere: ClientModelFilterInput
    $assetAllocationInput: GetAssetAllocationInput!
  ) {
    userprofile_viewer {
      clientUser(input: $input) {
        id
        accounts {
          accountNickname
          financialAccountId
        }
        accountGroups {
          id
          name
          accounts {
            id
            financialAccountId
          }
        }
      }
    }
    clientsummary_viewer {
      client(where: $clientWhere) {
        accounts {
          accountDefaultName
          accountId
          accountNumber
          managementStyle
          custodian
          totalBalance
        }
        userId
        getAssetAllocations(input: $assetAllocationInput) {
          accountBalance
          accountDefaultName
          accountId
          accountNumber
          allocationId
          asOfDate
          assetClass
          assetClassBalance
          managementStyle
          subAssetClass
          subAssetClassBalance
        }
      }
    }
  }
`;

interface AssetAllocationPreviewQueryReaderProps {
  queryRef: PreloadedQuery<AssetAllocationPreviewQueryReaderQuery>;
  filterValues: FilterItem[];
  setFilterValues: (filterValues: FilterItem[]) => void;
}

export const AssetAllocationPreviewQueryReader = ({
  queryRef,
  filterValues,
  setFilterValues,
}: AssetAllocationPreviewQueryReaderProps) => {
  const data = usePreloadedQuery(
    AssetAllocationPreviewQueryReaderQueryNode,
    queryRef
  );

  const [asOfDate, setAsOfDate] = useState<string | null>(null);

  const clientUserProfile = data.userprofile_viewer?.clientUser[0];
  const clientSummary = data.clientsummary_viewer.client.find(
    (o) => o.userId === clientUserProfile?.id
  );

  const dataArray = clientSummary?.getAssetAllocations;

  const accountGroups =
    clientUserProfile?.accountGroups as UserProfileAccountGroup[];

  const accounts = combineUserProfileAndClientSummaryFinancialAccountInfo(
    clientUserProfile?.accounts as UserProfileFinancialAccountNickname[],
    accountGroups,
    clientSummary?.accounts as InternalFinancialAccountInfo[]
  );

  const { selectedAccounts } = useContext(GlobalFilterContext);

  const userProfileAccounts =
    clientUserProfile?.accounts as UserProfileFinancialAccountNickname[];

  return (
    <ReportPreviewWrapper asOfDate={asOfDate}>
      <AssetAllocationDetailView
        data={dataArray}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        setAsOfDate={setAsOfDate}
        accountBalances={
          clientSummary?.accounts
            ? (clientSummary?.accounts as unknown as Pick<
                InternalFinancialAccountInfo,
                'accountId' | 'totalBalance'
              >[])
            : []
        }
        accounts={accounts}
        selectedAccounts={selectedAccounts}
        userProfileAccounts={userProfileAccounts}
        disableLinks
      />
    </ReportPreviewWrapper>
  );
};
