import { Box, Paper } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import GoBackLinkButton from '../../components/buttons/GoBackLinkButton';
import { useParams } from 'react-router';
import { ClientSummaryInfo } from '../../components/summary/ClientSummaryInfo';
import { EditClientInfoConfigurationViewQuery } from './__generated__/EditClientInfoConfigurationViewQuery.graphql';
import { PreloadedQuery, useMutation, usePreloadedQuery } from 'react-relay';
import { ManageDisplayNameRow } from '@newedge/common';
import { useCallback } from 'react';
import { EditClientInfoConfigurationView_ManageDisplayNameRowMutation } from './__generated__/EditClientInfoConfigurationView_ManageDisplayNameRowMutation.graphql';

export const EditClientInfoConfigurationViewQueryNode = graphql`
  query EditClientInfoConfigurationViewQuery(
    $getClientUserInput: GetClientUserInput!
  ) {
    clientsummary_viewer {
      client {
        userId
        summary {
          netAssets
        }
      }
    }
    userprofile_viewer {
      clientUser(input: $getClientUserInput) {
        id
        displayName
      }
    }
  }
`;

const ManageDisplayNameRowMutationNode = graphql`
  mutation EditClientInfoConfigurationView_ManageDisplayNameRowMutation(
    $input: UpdateClientUserInput!
  ) {
    UpdateClientUser(input: $input) {
      errors {
        code
        message
      }
      partitionKey
      request {
        correlationId
        status
      }
      userState {
        displayName
      }
      viewer {
        displayName
      }
    }
  }
`;

interface EditClientInfoConfigurationViewProps {
  routeQueryRef: PreloadedQuery<EditClientInfoConfigurationViewQuery>;
}

export const EditClientInfoConfigurationView = ({
  routeQueryRef,
}: EditClientInfoConfigurationViewProps) => {
  const { clientUserId: clientId = '' } = useParams();
  const data = usePreloadedQuery(
    EditClientInfoConfigurationViewQueryNode,
    routeQueryRef
  );

  const clientUserProfile = data.userprofile_viewer?.clientUser.find(
    (x) => x.id === clientId
  );

  const clientSummary = data.clientsummary_viewer?.client.find(
    (x) => x.userId === clientId
  );

  const [commitUpdate] =
    useMutation<EditClientInfoConfigurationView_ManageDisplayNameRowMutation>(
      ManageDisplayNameRowMutationNode
    );

  const onUpdateClick = useCallback(
    (newDisplayName: string) => {
      commitUpdate({
        variables: {
          input: {
            clientId,
            displayName: newDisplayName,
          },
        },
        updater: (store) => {
          const root = store.getRoot();
          const userProfileViewer = root.getLinkedRecord('userprofile_viewer');
          const clientUser = userProfileViewer?.getLinkedRecords(
            `clientUser(input:{"clientUserIds":["${clientId}"]})`
          );
          if (clientUser && clientUser[0]) {
            clientUser[0].setValue(newDisplayName, 'displayName');
          }
        },
      });
    },
    [commitUpdate]
  );

  return (
    <>
      <Box sx={{ padding: 1 }}>
        <GoBackLinkButton
          link={`/my/client-configuration/${clientId}`}
          label="Client's Profile"
        />
      </Box>
      <Box sx={{ display: 'flex' }}>
        <ClientSummaryInfo
          displayName={
            clientUserProfile?.displayName ? clientUserProfile?.displayName : ''
          }
          netAssets={clientSummary?.summary?.netAssets}
        />
      </Box>
      <Paper
        sx={(theme) => ({
          '&>*:nth-child(even)': {
            backgroundColor: theme.extensions.grey[16],
          },
          my: 4,
        })}
      >
        <Box sx={{ px: 2, py: 2, fontWeight: 'bold' }}>Manage Profile</Box>
        <ManageDisplayNameRow
          displayName={
            clientUserProfile?.displayName ? clientUserProfile?.displayName : ''
          }
          onUpdateClick={onUpdateClick}
        />
      </Paper>
    </>
  );
};

export default EditClientInfoConfigurationView;
