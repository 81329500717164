import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Header } from '../components/root/topbar/Header';

interface WelcomeAdvisorWrapperProps extends PropsWithChildren {
  displayName: string;
}

export const WelcomeAdvisorWrapper = ({
  displayName,
  children,
}: WelcomeAdvisorWrapperProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
          marginTop: '95px',
        },
      })}
    >
      <Box
        sx={(theme) => ({
          padding: theme.spacing(3),
          marginLeft: '65px',
          width: '90vw',
          maxWidth: '1400px',
          [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            overflow: 'hidden',
            width: '100%',
          },
        })}
      >
        <Header displayName={displayName} />
        {children}
      </Box>
    </Box>
  );
};
