import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import { PropsWithChildren, ReactNode, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ReportPreviewAccordionProps extends PropsWithChildren {
  title: string;
}

export const ReportPreviewAccordion = ({
  title,
  children,
}: ReportPreviewAccordionProps) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      disableGutters
      sx={(theme) => ({
        '&:last-of-type': {
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
        },
        '&:first-of-type': {
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        },
        backgroundColor: 'unset',
      })}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ fontSize: '4rem' }} />}
        sx={(theme) => {
          return {
            backgroundColor: theme.extensions.filter[24],
            borderRadius: '10px',
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
              justifyContent: 'space-between',
              my: 0.5,
              minHeight: '54px',
            },
          };
        }}
      >
        <Typography variant='h3'>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 'unset' }}>
        {expanded ? children : null}
      </AccordionDetails>
    </Accordion>
  );
};
