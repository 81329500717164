import {
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Box,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ReactNode, useState } from 'react';
import {
  ColumnDataType,
  HeadCell,
  Order,
} from '../../components/sortable-table/@types';
import { SortableTable } from '../../components/sortable-table/SortableTable';
import graphql from 'babel-plugin-relay/macro';
import { PropsWithRelayRouteQueryRef } from '@newedge/relay';
import { DashboardPageQuery } from './__generated__/DashboardPageQuery.graphql';
import { usePreloadedQuery } from 'react-relay';
import GoForwardLinkButton from '../../components/buttons/GoForwardLinkButton';
import { WelcomeAdvisorWrapper } from '../WelcomeAdvisorWrapper';

export const DashboardPageQueryNode = graphql`
  query DashboardPageQuery($input: GetClientUserInput!) {
    clientsummary_viewer {
      client {
        userId
        summary {
          netAssets
        }
      }
    }
    userprofile_viewer {
      displayName
      clientUser(input: $input) {
        displayName
        firstName
        lastName
        id
      }
    }
  }
`;

interface ManageClientsInputProps {
  handleSearch: (value: string) => void;
}

const ManageClients = ({ handleSearch }: ManageClientsInputProps) => {
  return (
    <div>
      <Typography
        variant={'h5'}
        sx={(theme) => ({ paddingBottom: theme.spacing(1) })}
      >
        Manage Clients
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          sx={(theme) => ({
            border: 1,
            borderColor: theme.extensions.grey[28],
            borderRadius: 1,
          })}
          type='search'
          variant='outlined'
          color='secondary'
          size='small'
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </div>
  );
};

interface AccountTableModel {
  clientName: string | null;
  netAssets: number;
  link: ReactNode;
}

interface AccountTableProps {
  accountData: AccountTableModel[];
}

const AccountTable = ({ accountData }: AccountTableProps) => {
  const headers: HeadCell<AccountTableModel>[] = [
    { label: 'Name', id: 'clientName', dataType: ColumnDataType.String },
    { label: 'Net Assets', id: 'netAssets', dataType: ColumnDataType.Currency },
    { label: '', id: 'link', dataType: ColumnDataType.Node },
  ];
  return (
    <SortableTable
      headCells={headers}
      dataRows={accountData}
      initialSortColumn='clientName'
      initialSortDirection={Order.Asc}
      sx={{
        minWidth: '100%',
      }}
    />
  );
};

type DashboardPageProps = PropsWithRelayRouteQueryRef<DashboardPageQuery>;

const DashboardPage = ({ routeQueryRef }: DashboardPageProps) => {
  const data = usePreloadedQuery(DashboardPageQueryNode, routeQueryRef);

  const [searchValue, setSearchValue] = useState<string>('');
  const keysToIgnore: Array<keyof AccountTableModel> = ['link'];

  const accountData: AccountTableModel[] = data.clientsummary_viewer.client
    .map((o) => {
      const clientUser = data.userprofile_viewer?.clientUser.find(
        (i) => i.id === o.userId
      );
      return {
        clientName: clientUser ? `${clientUser.lastName}, ${clientUser.firstName}` : '',
        netAssets: o.summary?.netAssets,
        link: (
          <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <GoForwardLinkButton
              link={`/my/client-configuration/${o.userId}`}
              label='Configure'
            />
            <GoForwardLinkButton
              link={`/my/client-preview/${o.userId}`}
              label='Preview'
            />
          </Box>
        ),
      };
    })
    .filter((client) => {
      //if searchValue is blank ignore
      if (searchValue) {
        //Convert all data props to values to search thru
        return Object.entries(client).some(
          (value: any) =>
            !keysToIgnore.includes(value[0]) &&
            typeof value[1] !== 'object' &&
            value[1]
              ?.toString()
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) > -1
        );
      }
      return true;
    });

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  return (
    <WelcomeAdvisorWrapper
      displayName={data.userprofile_viewer?.displayName || ''}
    >
      <Grid
        container
        item
        xs={12}
        sx={(theme) => ({ marginTop: theme.spacing(4) })}
      >
        <Grid item xs={4}>
          <ManageClients handleSearch={handleSearch} />
        </Grid>
      </Grid>
      <Grid container item xs={12} sx={{ width: '50%' }}>
        <Grid item xs={12} sx={(theme) => ({ marginTop: theme.spacing(4) })}>
          <AccountTable accountData={accountData} />
        </Grid>
      </Grid>
    </WelcomeAdvisorWrapper>
  );
};

export default DashboardPage;
