/**
 * @generated SignedSource<<e8e9ed6f791be260dbacaf3a620fd1f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GetClientUserInput = {
  clientUserIds: ReadonlyArray<any>;
};
export type PreviewWrapperQuery$variables = {
  input: GetClientUserInput;
};
export type PreviewWrapperQuery$data = {
  readonly clientsummary_viewer: {
    readonly client: ReadonlyArray<{
      readonly accounts: ReadonlyArray<{
        readonly accountDefaultName: string;
        readonly accountId: number;
      } | null> | null;
      readonly userId: any;
    }>;
  };
  readonly userprofile_viewer: {
    readonly clientUser: ReadonlyArray<{
      readonly accountGroups: ReadonlyArray<{
        readonly accounts: ReadonlyArray<{
          readonly financialAccountId: number;
          readonly id: any;
        }>;
        readonly id: string;
        readonly name: string;
      }>;
      readonly accounts: ReadonlyArray<{
        readonly accountNickname: string;
        readonly financialAccountId: number;
      }>;
      readonly displayName: string;
      readonly id: any;
    }>;
  } | null;
};
export type PreviewWrapperQuery = {
  response: PreviewWrapperQuery$data;
  variables: PreviewWrapperQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "financialAccountId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "input",
      "variableName": "input"
    }
  ],
  "concreteType": "ClientUserViewModel",
  "kind": "LinkedField",
  "name": "clientUser",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserAccount",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountNickname",
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountGroup",
      "kind": "LinkedField",
      "name": "accountGroups",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Account",
          "kind": "LinkedField",
          "name": "accounts",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ClientModel",
  "kind": "LinkedField",
  "name": "client",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientAccountModel",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountDefaultName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PreviewWrapperQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserProfileViewer",
        "kind": "LinkedField",
        "name": "userprofile_viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientSummaryViewer",
        "kind": "LinkedField",
        "name": "clientsummary_viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PreviewWrapperQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserProfileViewer",
        "kind": "LinkedField",
        "name": "userprofile_viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientSummaryViewer",
        "kind": "LinkedField",
        "name": "clientsummary_viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b76a0b141c181fa04d5bb9aa0e04f0c4",
    "id": null,
    "metadata": {},
    "name": "PreviewWrapperQuery",
    "operationKind": "query",
    "text": "query PreviewWrapperQuery(\n  $input: GetClientUserInput!\n) {\n  userprofile_viewer {\n    clientUser(input: $input) {\n      id\n      displayName\n      accounts {\n        accountNickname\n        financialAccountId\n      }\n      accountGroups {\n        id\n        name\n        accounts {\n          id\n          financialAccountId\n        }\n      }\n    }\n    id\n  }\n  clientsummary_viewer {\n    client {\n      accounts {\n        accountDefaultName\n        accountId\n      }\n      userId\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "45f50cef388853b8b15c603adcf88492";

export default node;
