/**
 * @generated SignedSource<<a258eb97c7d465b697400c9cf00f447f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateClientAccountGroupInput = {
  accountGroupId: any;
  accountGroupName: string;
  clientDisplayName: string;
  clientId: any;
};
export type CreateAccountGroupInputView_CreateClientAccountGroupMutation$variables = {
  createClientAccountGroupInput: CreateClientAccountGroupInput;
};
export type CreateAccountGroupInputView_CreateClientAccountGroupMutation$data = {
  readonly CreateClientAccountGroup: {
    readonly accountGroup: {
      readonly accounts: ReadonlyArray<{
        readonly financialAccountId: number;
        readonly id: any;
      }>;
      readonly id: string;
      readonly name: string;
    } | null;
  };
};
export type CreateAccountGroupInputView_CreateClientAccountGroupMutation = {
  response: CreateAccountGroupInputView_CreateClientAccountGroupMutation$data;
  variables: CreateAccountGroupInputView_CreateClientAccountGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "createClientAccountGroupInput"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "createClientAccountGroupInput"
      }
    ],
    "concreteType": "ClientUserPayload",
    "kind": "LinkedField",
    "name": "CreateClientAccountGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountGroup",
        "kind": "LinkedField",
        "name": "accountGroup",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "accounts",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "financialAccountId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateAccountGroupInputView_CreateClientAccountGroupMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateAccountGroupInputView_CreateClientAccountGroupMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "4d00b8bd0805eff0d1e351c853c7b762",
    "id": null,
    "metadata": {},
    "name": "CreateAccountGroupInputView_CreateClientAccountGroupMutation",
    "operationKind": "mutation",
    "text": "mutation CreateAccountGroupInputView_CreateClientAccountGroupMutation(\n  $createClientAccountGroupInput: CreateClientAccountGroupInput!\n) {\n  CreateClientAccountGroup(input: $createClientAccountGroupInput) {\n    accountGroup {\n      id\n      name\n      accounts {\n        id\n        financialAccountId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f4c23dde4f8653f60e2e995ebb130f4c";

export default node;
