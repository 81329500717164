import { ArrowRightAlt } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';

interface GoBackLinkButtonProps {
  link: string;
  label: string;
}

export const GoBackLinkButton = ({ link, label }: GoBackLinkButtonProps) => {
  const navigate = useNavigate();
  return (
    <Button
      variant='text'
      color='iron'
      onClick={() => {
        navigate(link);
      }}
      sx={{ margin: 0, padding: 0 }}
      data-testid='GoBackLinkButton'
    >
      <ArrowRightAlt
        sx={{ transform: 'rotate(180deg)', marginRight: '1rem' }}
      />
      {label}
    </Button>
  );
};

export default GoBackLinkButton;
