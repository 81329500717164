import { ColumnDataType } from '../../sortable-table/@types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  TextField,
} from '@mui/material';
import TransferSelectionPanel from './TransferSelectionPanel';
import SearchIcon from '@mui/icons-material/Search';

interface TransferListModalProps<T> {
  open: boolean;
  close: () => void;
  parentTitle: string;
  leftTitle: string;
  rightTitle: string;
  addButtonTitle: string;
  removeButtonTitle: string;
  onAddButtonClick: (selected: T[]) => void;
  onRemoveButtonClick: (selected: T[]) => void;
  leftData: T[];
  rightData: T[];
  metadata: {
    id: keyof T;
    displayValue: keyof T;
    label: string;
    dataType: ColumnDataType;
  };
  onSave: () => void;
  onCancel: () => void;
  onSearch: (value: string) => void;
}

export const TransferListModal = <T,>({
  open,
  close,
  parentTitle,
  leftTitle,
  rightTitle,
  addButtonTitle,
  removeButtonTitle,
  onAddButtonClick,
  onRemoveButtonClick,
  leftData,
  rightData,
  metadata,
  onSave,
  onCancel,
  onSearch,
}: TransferListModalProps<T>) => {
  return (
    <Dialog
      open={open}
      onClose={() => close()}
      aria-labelledby='transfer-list'
      maxWidth='md'
      sx={{ border: '1px solid #393939' }}
      PaperProps={{ sx: { border: '1px solid #393939' } }}
    >
      <DialogContent>
        <Box
          sx={(theme) => ({
            paddingBottom: theme.spacing(2),
            ...theme.typography.h5,
            fontWeight: 'bold',
          })}
        >
          {parentTitle}
        </Box>
        <Box>
          <TextField
            type='search'
            variant='outlined'
            color='secondary'
            size='small'
            onChange={(event) => onSearch(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={(theme) => ({
              marginBottom: theme.spacing(2),
              border: 1,
              borderColor: '#393939',
              borderRadius: '5px',
              backgroundColor: '#191919',
            })}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
          <TransferSelectionPanel
            title={leftTitle}
            buttonTitle={addButtonTitle}
            buttonColor='primary'
            onButtonClick={onAddButtonClick}
            data={leftData}
            metadata={metadata}
          />
          <TransferSelectionPanel
            title={rightTitle}
            buttonTitle={removeButtonTitle}
            buttonColor='secondary'
            onButtonClick={onRemoveButtonClick}
            data={rightData}
            metadata={metadata}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: 'center', borderTop: '1px solid #393939' }}
      >
        <Button
          onClick={() => {
            close();
            onSave();
          }}
        >
          Save
        </Button>
        <Button
          onClick={() => {
            close();
            onCancel();
          }}
          color='secondary'
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransferListModal;
