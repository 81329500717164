import {
  ClientPortalFinancialAccount,
  UserProfileAccountGroup,
} from '@newedge/common';
import AccountGroupAccordion from '../../components/accordion/AccountGroupAccordion';

interface AccountGroupAccordionListMap {
  [accountGroupName: string]: ClientPortalFinancialAccount[];
}

interface AccountGroupAccordionListProps {
  accounts: ClientPortalFinancialAccount[];
  accountGroups: UserProfileAccountGroup[];
  filteredAccounts: ClientPortalFinancialAccount[];
  filteredAccountGroups: UserProfileAccountGroup[];
  searchValue: string;
}

export const AccountGroupAccordionList = ({
  accounts,
  accountGroups,
  filteredAccounts,
  filteredAccountGroups,
  searchValue,
}: AccountGroupAccordionListProps) => {
  const unassignedAccounts = [] as ClientPortalFinancialAccount[];
  const filteredUnassignedAccounts = [] as ClientPortalFinancialAccount[];
  let editAccountGroupData = {} as AccountGroupAccordionListMap;
  let filteredEditAccountGroupData = {} as AccountGroupAccordionListMap;

  filteredAccountGroups.forEach((group) => {
    filteredEditAccountGroupData[group.name] = [];
  });

  filteredAccounts.forEach((acct: ClientPortalFinancialAccount) => {
    if (acct.accountGroupEntry) {
      filteredEditAccountGroupData[acct.accountGroupEntry.name].push(acct);
    } else {
      filteredUnassignedAccounts.push(acct);
    }
  });

  accountGroups.forEach((group) => {
    editAccountGroupData[group.name] = [];
  });

  accounts.forEach((acct: ClientPortalFinancialAccount) => {
    if (
      acct.accountGroupEntry &&
      editAccountGroupData[acct.accountGroupEntry.name]
    ) {
      editAccountGroupData[acct.accountGroupEntry.name].push(acct);
    } else {
      unassignedAccounts.push(acct);
    }
  });

  return (
    <>
      {Object.keys(filteredEditAccountGroupData)
        .sort((a, b) => a.localeCompare(b))
        .map((accountGroupName) => {
          return (
            <AccountGroupAccordion
              key={accountGroupName}
              name={accountGroupName}
              userProfileInfo={filteredAccountGroups.find(
                (o) => o.name === accountGroupName
              )}
              accounts={filteredEditAccountGroupData[accountGroupName]}
              unfilteredAccounts={editAccountGroupData[accountGroupName]}
              availableAccounts={unassignedAccounts}
              accountGroupNames={filteredAccountGroups.map((x) => x.name)}
            />
          );
        })}
      {searchValue.length > 0 &&
      filteredUnassignedAccounts.length === 0 ? null : (
        <AccountGroupAccordion
          name='View Ungrouped Accounts'
          accounts={filteredUnassignedAccounts}
          unfilteredAccounts={[]}
        />
      )}
    </>
  );
};

export default AccountGroupAccordionList;
