import {
  AppBar,
  Box,
  Link,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';

interface Props {
  children: React.ReactElement;
}

function ElevationScroll(props: Props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

interface RevertToolbarProps {
  displayName?: string;
}

export const RevertToolbar = ({ displayName }: RevertToolbarProps) => {
  const navigate = useNavigate();

  return (
    <ElevationScroll>
      <AppBar>
        <Toolbar sx={{ margin: 'auto' }}>
          <Typography component='div'>
            <Box
              sx={(theme) => ({
                padding: theme.spacing(3),
                width: '90vw',
                maxWidth: '1400px',
              })}
            >
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.extensions.grey[20],
                  justifyContent: 'center',
                  display: 'flex',
                  borderRadius: 5,
                  py: 1,
                })}
                data-testid='ClientPreviewRevertToolbar'
              >
                <Typography
                  component='div'
                  sx={{ justifyContent: 'center', display: 'flex' }}
                >
                  You are viewing
                  <Box sx={{ paddingLeft: 0.5, fontWeight: 'bold' }}>
                    {displayName}
                  </Box>
                  .
                  <Link
                    component='button'
                    onClick={() => {
                      navigate('/my/clients');
                    }}
                    sx={{
                      paddingLeft: 1,
                      paddingRight: 0.5,
                      textDecoration: 'underline',
                      color: '#4152da',
                      fontWeight: 'bold',
                    }}
                  >
                    Revert
                  </Link>
                  to Internal User Portal
                </Typography>
              </Box>
            </Box>
          </Typography>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
};
