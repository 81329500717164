import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CreateAccountGroupInput from './CreateAccountGroupInput';
import { UserProfileAccountGroup } from '@newedge/common';

interface CreateAccountGroupDialogProps {
  clientAccountGroups: UserProfileAccountGroup[];
  clientId: string;
  displayName: string;
}

export const CreateAccountGroupDialog = ({
  clientAccountGroups,
  clientId,
  displayName,
}: CreateAccountGroupDialogProps) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>New Account Group</Button>
      <Dialog open={open} onClose={handleClose}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            position: 'absolute',
          }}
        >
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
            sx={{
              width: '5rem',
              height: '5rem',
            }}
          >
            <CloseIcon sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </Box>
        <DialogTitle>New Account Group</DialogTitle>
        <DialogContent>
          <CreateAccountGroupInput
            label='Create Account Group'
            clientAccountGroups={clientAccountGroups.map((x) => x.name)}
            clientId={clientId}
            displayName={displayName}
            handleModalClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
