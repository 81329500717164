import graphql from 'babel-plugin-relay/macro';
import {
  FilterItem,
  UserProfileAccountGroup,
  UserProfileFinancialAccountNickname,
} from '@newedge/common';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { AccountBalancesPreviewQueryReaderQuery } from './__generated__/AccountBalancesPreviewQueryReaderQuery.graphql';
import { useContext, useState } from 'react';
import { ReportPreviewWrapper } from '../ReportPreviewWrapper';
import { AccountBalancesModel, AccountBalancesView } from '@newedge/reports';
import { GlobalFilterContext } from '../../../components/context/GlobalFilterContext';
import combineUserProfileAndClientSummaryFinancialAccountInfo from '../../../models/InternalPortalFinancialAccount';
import { InternalFinancialAccountInfo } from '../../../@types/global';

export const AccountBalancesPreviewQueryReaderQueryNode = graphql`
  query AccountBalancesPreviewQueryReaderQuery(
    $input: GetClientUserInput!
    $clientWhere: ClientModelFilterInput
    $accountBalancesInput: GetAccountBalancesInput!
  ) {
    userprofile_viewer {
      clientUser(input: $input) {
        id
        accounts {
          accountNickname
          financialAccountId
        }
        accountGroups {
          id
          name
          accounts {
            id
            financialAccountId
          }
        }
      }
    }
    clientsummary_viewer {
      client(where: $clientWhere) {
        accounts {
          accountDefaultName
          accountId
          accountNumber
          managementStyle
          custodian
          totalBalance
        }
        userId
        getAccountBalances(input: $accountBalancesInput) {
          accountDefaultName
          accountId
          accountType
          asOfDate
          cashBalance
          dailyChange
          isLiability
          marketValue
          managementStyle
          totalBalance
          unrealizedGl
          unrealizedGlPercent
          valuationId
          beginningMarketValue
          endingMarketValue
          netCashFlows
        }
      }
    }
  }
`;

interface AccountBalancesPreviewQueryReaderProps {
  queryRef: PreloadedQuery<AccountBalancesPreviewQueryReaderQuery>;
  filterValues: FilterItem[];
  setFilterValues: (filterValues: FilterItem[]) => void;
}

export const AccountBalancesPreviewQueryReader = ({
  queryRef,
  filterValues,
  setFilterValues,
}: AccountBalancesPreviewQueryReaderProps) => {
  const data = usePreloadedQuery(
    AccountBalancesPreviewQueryReaderQueryNode,
    queryRef
  );

  const [asOfDate, setAsOfDate] = useState<string | null>(null);

  const clientUserProfile = data.userprofile_viewer?.clientUser[0];
  const clientSummary = data.clientsummary_viewer.client.find(
    (o) => o.userId === clientUserProfile?.id
  );

  const date = clientSummary?.getAccountBalances?.[0]?.asOfDate;

  const accountBalances =
    clientSummary?.getAccountBalances as AccountBalancesModel[];

  const accountGroups =
    clientUserProfile?.accountGroups as UserProfileAccountGroup[];

  const accounts = combineUserProfileAndClientSummaryFinancialAccountInfo(
    clientUserProfile?.accounts as UserProfileFinancialAccountNickname[],
    accountGroups,
    clientSummary?.accounts as InternalFinancialAccountInfo[]
  );

  const { selectedAccounts } = useContext(GlobalFilterContext);

  return (
    <ReportPreviewWrapper asOfDate={asOfDate}>
      <AccountBalancesView
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        setAsOfDate={setAsOfDate}
        date={date}
        accountBalances={accountBalances}
        accounts={accounts}
        accountGroups={accountGroups}
        selectedAccounts={selectedAccounts}
        disableLinks
      />
    </ReportPreviewWrapper>
  );
};
