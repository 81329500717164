import { ReactNode } from 'react';

export interface HeadCell<T> {
  id: keyof T;
  label: string;
  dataType: ColumnDataType;
  precision?: number;
  maxLength?: number;
  nullDisplay?: string;
  dataAlignment?: DataAlignment;
  icon?: (data: T) => ReactNode;
  transformFn?: (value: number | null) => string;
  customSortRenderer?: (
    setOrder: (order: Order) => void,
    setOrderBy: (orderBy: keyof T) => void,
    close: () => void
  ) => ReactNode;
  customCellRenderer?: (
    value: any,
    headCell: HeadCell<T>,
    row: T,
    format: (value: any, row: HeadCell<T>) => JSX.Element
  ) => ReactNode;
  relatedIds?: (keyof T)[];
  minimumDecimalToDisplay?: number;
  customSortComparator?: <Key extends keyof T>(
    order: Order,
    orderBy: Key
  ) => (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number;
}

export enum ColumnDataType {
  String,
  Number,
  Percent,
  Currency,
  Date,
  Node,
}

export enum DataAlignment {
  Left,
  Right,
}

export enum Order {
  Asc = 'asc',
  Desc = 'desc',
}
