import graphql from 'babel-plugin-relay/macro';
import {
  AccountActivityFilterOptions,
  AccountActivityView,
} from '@newedge/reports';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import {
  AccountActivityPreviewQueryReaderQuery,
  AccountActivityPreviewQueryReaderQuery$data,
} from './__generated__/AccountActivityPreviewQueryReaderQuery.graphql';
import {
  ClientPortalFinancialAccount,
  FilterItem,
  UserProfileAccountGroup,
  UserProfileFinancialAccountNickname,
} from '@newedge/common';
import { combineUserProfileAndClientSummaryFinancialAccountInfo } from '../../../models/InternalPortalFinancialAccount';
import { AccountActivityModel } from '@newedge/reports/src/views/account-activity/@types';
import { findNicknameForAccountId } from '@newedge/common';
import { InternalFinancialAccountInfo } from '../../../@types/global';
import { ReportPreviewWrapper } from '../ReportPreviewWrapper';

export const AccountActivityPreviewQueryReaderQueryNode = graphql`
  query AccountActivityPreviewQueryReaderQuery(
    $input: GetClientUserInput!
    $clientWhere: ClientModelFilterInput
    $allAccounts: GetAccountActivityInput!
    $selectedAccounts: GetAccountActivityInput!
    $filters: AccountActivityDataModelFilterInput
  ) {
    userprofile_viewer {
      clientUser(input: $input) {
        id
        accounts {
          accountNickname
          financialAccountId
        }
        accountGroups {
          id
          name
          accounts {
            id
            financialAccountId
          }
        }
      }
    }
    clientsummary_viewer {
      client(where: $clientWhere) {
        accounts {
          accountDefaultName
          accountId
          accountNumber
          managementStyle
          custodian
          totalBalance
        }
        userId
        getAccountActivity {
          data(input: $selectedAccounts, where: $filters) {
            accountId
            accountNumber
            amount
            transactionDate
            ticker
            productName
            units
            price
            transactionType
            managementStyle
          }
          filterOptions(input: $allAccounts) {
            managementStyles
            maxTransactionAmount
            minTransactionAmount
            transactionTypes
          }
        }
      }
    }
  }
`;

const addAccountNickname = (
  activityData: AccountActivityPreviewQueryReaderQuery$data['clientsummary_viewer']['client'][0]['getAccountActivity']['data'],
  accountData: ClientPortalFinancialAccount[]
): AccountActivityModel[] => {
  return activityData?.map((o: any) => {
    return {
      ...o,
      accountNickname: findNicknameForAccountId(accountData, o.accountId),
    } as AccountActivityModel;
  });
};

interface AccountActivityPreviewQueryReaderProps {
  queryRef: PreloadedQuery<AccountActivityPreviewQueryReaderQuery>;
  filterValues: FilterItem[];
  setFilterValues: (filterValues: FilterItem[]) => void;
  hasInitialFilters: boolean;
}

export const AccountActivityPreviewQueryReader = ({
  queryRef,
  filterValues,
  setFilterValues,
  hasInitialFilters,
}: AccountActivityPreviewQueryReaderProps) => {
  const data = usePreloadedQuery(
    AccountActivityPreviewQueryReaderQueryNode,
    queryRef
  );

  const clientUserProfile = data.userprofile_viewer?.clientUser[0];
  const clientSummary = data.clientsummary_viewer.client.find(
    (o) => o.userId === clientUserProfile?.id
  );

  const accountGroups =
    clientUserProfile?.accountGroups as UserProfileAccountGroup[];

  const accounts: ClientPortalFinancialAccount[] =
    combineUserProfileAndClientSummaryFinancialAccountInfo(
      clientUserProfile?.accounts as UserProfileFinancialAccountNickname[],
      accountGroups,
      clientSummary?.accounts as InternalFinancialAccountInfo[]
    );

  const filteredAccountActivityData = addAccountNickname(
    clientSummary?.getAccountActivity.data ?? [],
    accounts
  );

  return (
    <ReportPreviewWrapper>
      <AccountActivityView
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        filterOptions={
          clientSummary?.getAccountActivity
            .filterOptions as AccountActivityFilterOptions
        }
        hasInitialFilters={hasInitialFilters}
        accounts={accounts}
        filteredAccountActivityData={filteredAccountActivityData}
      />
    </ReportPreviewWrapper>
  );
};
