import { Box, Typography } from '@mui/material';

type WelcomeHeaderProps = {
  userDisplayName?: string | null;
};
function WelcomeHeader({ userDisplayName }: WelcomeHeaderProps) {
  return (
    <Box sx={(theme) => ({ backgroundColor: theme.extensions.grey[24] })}>
      <Typography
        variant='h1'
        sx={(theme) => ({
          ...theme.typography.special,
          padding: theme.spacing(1),
          justifyContent: 'center',
        })}
      >
        Welcome, {userDisplayName}!
      </Typography>
    </Box>
  );
}

export default WelcomeHeader;
