import {
  Avatar,
  Grid,
  Theme,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';
import React, { useState } from 'react';
import LogoutDialog from '../../modals/LogoutDialog';

type UserAccountMenuProps = {
  userDisplayName?: string | null;
};
function UserAccountMenu({ userDisplayName }: UserAccountMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const userAccountMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id='user-account-menu'
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem>
        <LogoutDialog />
      </MenuItem>
    </Menu>
  );

  return (
    <Grid container direction='row' spacing={2} alignItems='center'>
      <Grid item sx={{marginTop: '1.5rem'}}>
        <Typography
          sx={{ display: 'inline', textAlign: 'center', fontSize: 16 }}
        >
          {userDisplayName}
        </Typography>
        <IconButton onClick={handleMenuOpen}>
          <Avatar sx={(theme) => ({ color: theme.palette.text.primary, backgroundColor:  theme.palette.secondary.main})} />
        </IconButton>
      </Grid>
      {userAccountMenu}
    </Grid>
  );
}

export default UserAccountMenu;
