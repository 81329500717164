import AccountNicknameConfigure from './ClientAccountNicknameConfigure';
import SortableTable from '../../components/sortable-table/SortableTable';
import {
  ColumnDataType,
  HeadCell,
  Order,
} from '../../components/sortable-table/@types';
import { getUndefinedAtBeginningComparator } from '../../components/sortable-table/SortHelpers';
import { Typography } from '@mui/material';
import { UserAccessConfigurationRow } from './@types';
import { ClientPortalFinancialAccount } from '@newedge/common';

interface AccountPortalTableComponentProps {
  data: ClientPortalFinancialAccount[];
  clientId: string;
  handlerRefreshConfigurationView: () => void;
}

export const AccountPortalTableComponent = ({
  data,
  clientId,
  handlerRefreshConfigurationView,
}: AccountPortalTableComponentProps) => {
  const accountPortalRows: UserAccessConfigurationRow[] = data.map(
    (userAccessConfiguration) => ({
      id: userAccessConfiguration.financialAccountId,
      accountName: userAccessConfiguration.accountName,
      defaultAccountName: userAccessConfiguration.defaultAccountName,
      accountNumber: userAccessConfiguration.accountNumber,
      managementStyle: userAccessConfiguration.managementStyle,
      accountType: userAccessConfiguration.accountType,
      accountBalance: userAccessConfiguration.accountBalance
        ? userAccessConfiguration.accountBalance
        : 0,
      nicknameEntry: userAccessConfiguration.accountName,
      accountGroupId: userAccessConfiguration.accountGroupEntry?.id!,
      accountGroupName: userAccessConfiguration.accountGroupEntry?.name!,
      accounts: userAccessConfiguration.accountGroupEntry?.accounts!,
    })
  );

  const getAccountNickNameConfigure = (row: UserAccessConfigurationRow) => {
    return (
      <AccountNicknameConfigure
        nickname={row.accountName as string}
        defaultNickname={row.defaultAccountName}
        financialAccountId={row.id}
        clientId={clientId}
        isUpdated={handlerRefreshConfigurationView}
      />
    );
  };

  const headCells: HeadCell<UserAccessConfigurationRow>[] = [
    {
      id: 'nicknameEntry',
      dataType: ColumnDataType.Node,
      label: 'Account Name',
      customCellRenderer: (value, headCell, row, format) => {
        return getAccountNickNameConfigure(row);
      },
    },
    {
      id: 'accountNumber',
      dataType: ColumnDataType.String,
      label: 'Account Number',
    },
    {
      id: 'managementStyle',
      dataType: ColumnDataType.String,
      label: 'Management Style',
    },
    {
      id: 'accountType',
      dataType: ColumnDataType.String,
      label: 'Account Type',
    },
    {
      id: 'accountBalance',
      dataType: ColumnDataType.Currency,
      label: 'Account Balance',
    },
    {
      id: 'accountGroupName',
      dataType: ColumnDataType.String,
      label: 'Account Group',
      customCellRenderer: (value, headCell, row, format) => {
        return <Typography>{value}</Typography>;
      },
      customSortComparator: getUndefinedAtBeginningComparator,
    },
  ];

  return (
    <SortableTable
      headCells={headCells}
      dataRows={accountPortalRows}
      initialSortColumn='accountGroupName'
      initialSortDirection={Order.Asc}
    />
  );
};

export default AccountPortalTableComponent;
