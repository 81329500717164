import {
  PropsWithRelayRouteQueryRef,
  RelayRouteWithQuery,
} from '@newedge/relay';
import graphql from 'babel-plugin-relay/macro';
import { usePreloadedQuery } from 'react-relay';
import { Navigate, RouteObject, useParams, useRoutes } from 'react-router';
import UserAccessConfigurationView, {
  UserAccessConfigurationViewQueryNode,
} from './user-access-configuration/UserAccessConfigurationView';
import { InternalAccessPageWrapperQuery } from './__generated__/InternalAccessPageWrapperQuery.graphql';
import DashboardPage, {
  DashboardPageQueryNode,
} from './clients-dashboard/DashboardPage';
import { Skeleton } from '@mui/material';
import AccountGroupEditView, {
  AccountGroupEditViewQueryNode,
} from './account-group-edit/AccountGroupEdit';
import { PreviewWrapperLoader } from './client-preview/PreviewWrapper';
import { WelcomeAdvisorWrapper } from './WelcomeAdvisorWrapper';
import EditClientInfoConfigurationView, {
  EditClientInfoConfigurationViewQueryNode,
} from './edit-client-info/EditClientInfoConfigurationView';

export const InternalAccessPageWrapperQueryNode = graphql`
  query InternalAccessPageWrapperQuery {
    clientsummary_viewer {
      client {
        userId
      }
    }
    userprofile_viewer {
      id
      firstName
      lastName
      displayName
    }
  }
`;

type InternalAccessPageWrapperProps =
  PropsWithRelayRouteQueryRef<InternalAccessPageWrapperQuery>;

const ClientConfiguration = () => {
  const { clientUserId } = useParams();

  return (
    <RelayRouteWithQuery
      innerComponent={UserAccessConfigurationView}
      query={UserAccessConfigurationViewQueryNode}
      initialVariables={{
        getClientUserInput: {
          clientUserIds: [clientUserId],
        },
      }}
      fallback={<Skeleton variant='rectangular' width='100%' height={200} />}
    />
  );
};

const ClientProfileConfiguration = () => {
  const { clientUserId } = useParams();

  return (
    <RelayRouteWithQuery
      innerComponent={EditClientInfoConfigurationView}
      query={EditClientInfoConfigurationViewQueryNode}
      initialVariables={{
        getClientUserInput: {
          clientUserIds: [clientUserId],
        },
      }}
      fallback={<Skeleton variant='rectangular' width='100%' height={200} />}
    />
  );
};

const AccountGroupEditConfiguration = () => {
  const { clientUserId } = useParams();

  return (
    <RelayRouteWithQuery
      innerComponent={AccountGroupEditView}
      query={AccountGroupEditViewQueryNode}
      initialVariables={{
        getClientUserInput: {
          clientUserIds: [clientUserId],
        },
      }}
      fallback={<Skeleton variant='rectangular' width='100%' height={200} />}
    />
  );
};

export const InternalAccessPageWrapper = ({
  routeQueryRef,
}: InternalAccessPageWrapperProps) => {
  const data = usePreloadedQuery(
    InternalAccessPageWrapperQueryNode,
    routeQueryRef
  );

  const displayName = data.userprofile_viewer?.displayName || '';

  const internalAccessRoutes: RouteObject[] = [
    {
      path: 'clients',
      element: (
        <RelayRouteWithQuery
          innerComponent={DashboardPage}
          query={DashboardPageQueryNode}
          initialVariables={{
            input: {
              clientUserIds: data.clientsummary_viewer.client.map(
                (o) => o.userId
              ),
            },
          }}
          fallback={
            <Skeleton variant='rectangular' width='100%' height={200} />
          }
        />
      ),
    },
    {
      path: 'client-configuration/:clientUserId',
      element: (
        <WelcomeAdvisorWrapper displayName={displayName}>
          <ClientConfiguration />
        </WelcomeAdvisorWrapper>
      ),
    },
    {
      path: 'client-profile-management/:clientUserId',
      element: (
        <WelcomeAdvisorWrapper displayName={displayName}>
          <ClientProfileConfiguration />
        </WelcomeAdvisorWrapper>
      ),
    },
    {
      path: 'account-group-edit/:clientUserId',
      element: (
        <WelcomeAdvisorWrapper displayName={displayName}>
          <AccountGroupEditConfiguration />
        </WelcomeAdvisorWrapper>
      ),
    },
    {
      path: 'client-preview/:clientUserId',
      element: <PreviewWrapperLoader />,
    },
    {
      path: 'dashboard',
      element: <Navigate to='/my/clients' replace />,
    },
    {
      path: '*',
      element: <Navigate to='/error/404' replace />,
    },
  ];

  const routes = useRoutes(internalAccessRoutes);
  return <>{routes}</>;
};
