import { AllowedClaims, AppConfig } from '@newedge/auth';

const devDefaults: AppConfig = {
  oidcRedirect: 'http://localhost:3001/callback',
  oidcAuthority: 'https://edgecodevb2c.b2clogin.com',
  oidcTenant: 'edgecodevb2c.onmicrosoft.com',
  oidcFlow: 'B2C_1A_CLIENTPORTAL_SIGNIN',
  oidcClient: 'ceee32f9-ff85-4551-b930-45cdf3546ac2',
  apiDomain: 'https://qa.edgeco-dev.com/api/graphql',
  // apiDomain: 'https://localhost:5001/graphql',
  deployEnv: process.env.REACT_APP_DEPLOY_ENV
    ? process.env.REACT_APP_DEPLOY_ENV
    : '',
  appVersion: 'DEBUG',
  appName: 'wm-client-portal',
};

export const allowedClaims: AllowedClaims = {
  extension_wmClientPortal: true,
  extension_wmUserType: 'Internal',
};

/* @ts-ignore */
export const appConfig = window.AppConfig ?? devDefaults;
