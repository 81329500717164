import { Box } from '@mui/material';
import { AsOfDate } from '@newedge/reports';
import { PropsWithChildren } from 'react';
import { PageFooter } from '@newedge/common';

interface ReportPreviewWrapperProps extends PropsWithChildren {
  asOfDate?: string | null;
}

export const ReportPreviewWrapper = ({
  children,
  asOfDate,
}: ReportPreviewWrapperProps) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: 2 }}>
        {asOfDate !== undefined ? <AsOfDate asOfDate={asOfDate} /> : null}
      </Box>
      {children}
      <PageFooter />
    </Box>
  );
};
