import { Box, InputAdornment, TextField } from '@mui/material';
import {
  UserProfileAccountGroup,
  UserProfileFinancialAccountNickname,
} from '@newedge/common';
import { useParams } from 'react-router-dom';
import graphql from 'babel-plugin-relay/macro';
import {
  PreloadedQuery,
  usePreloadedQuery,
  UseQueryLoaderLoadQueryOptions,
} from 'react-relay';
import { UserAccessConfigurationViewQuery } from '../user-access-configuration/__generated__/UserAccessConfigurationViewQuery.graphql';
import _ from 'lodash';
import AccountGroupAccordionList from './AccountGroupAccordionList';
import GoBackLinkButton from '../../components/buttons/GoBackLinkButton';
import combineUserProfileAndClientSummaryFinancialAccountInfo from '../../models/InternalPortalFinancialAccount';
import SearchIcon from '@mui/icons-material/Search';
import { InternalFinancialAccountInfo } from '../../@types/global';
import { useState } from 'react';
import { CreateAccountGroupDialog } from '../../components/modals/create-account-group/CreateAccountGroupDialog';
import { ClientSummaryInfo } from '../../components/summary/ClientSummaryInfo';

export const AccountGroupEditViewQueryNode = graphql`
  query AccountGroupEditViewQuery($getClientUserInput: GetClientUserInput!) {
    clientsummary_viewer {
      client {
        userId
        accounts {
          accountDefaultName
          accountId
          accountNumber
          accountType
          managementStyle
          totalBalance
        }
        summary {
          netAssets
        }
      }
    }
    userprofile_viewer {
      displayName
      clientUser(input: $getClientUserInput) {
        id
        displayName
        accounts {
          accountNickname
          financialAccountId
        }
        accountGroups {
          accounts {
            financialAccountId
            id
          }
          id
          name
        }
      }
    }
  }
`;

interface UserAccessConfigurationWrapperProps {
  routeQueryRef: PreloadedQuery<UserAccessConfigurationViewQuery>;
  loadRouteQuery: (
    variables: { [key: string]: any },
    options?: UseQueryLoaderLoadQueryOptions
  ) => void;
}

const AccountGroupEditView = ({
  routeQueryRef,
  loadRouteQuery,
}: UserAccessConfigurationWrapperProps) => {
  const data = usePreloadedQuery(AccountGroupEditViewQueryNode, routeQueryRef);

  const { clientUserId: clientId = '' } = useParams();

  const clientUserProfile = data.userprofile_viewer?.clientUser.find(
    (x) => x.id === clientId
  );

  const clientSummary = data.clientsummary_viewer?.client.find(
    (x) => x.userId === clientId
  );

  const clientAccountNicknames: UserProfileFinancialAccountNickname[] =
    clientUserProfile?.accounts as UserProfileFinancialAccountNickname[];

  const clientAccountGroups: UserProfileAccountGroup[] =
    clientUserProfile?.accountGroups as UserProfileAccountGroup[];

  const summaryAccounts: InternalFinancialAccountInfo[] =
    clientSummary?.accounts as InternalFinancialAccountInfo[];

  const userAccessConfigurationData =
    combineUserProfileAndClientSummaryFinancialAccountInfo(
      clientAccountNicknames,
      clientAccountGroups,
      summaryAccounts
    );

  const [searchValue, setSearchValue] = useState<string>('');

  const filteredAccounts = userAccessConfigurationData.filter((acct) => {
    if (searchValue.length > 0) {
      return (
        acct.accountName.toLowerCase().indexOf(searchValue.toLowerCase()) >
          -1 ||
        acct.accountNumber.toLowerCase().indexOf(searchValue.toLowerCase()) >
          -1 ||
        (acct.accountBalance &&
          acct.accountBalance.toString().indexOf(searchValue.toLowerCase()) >
            -1)
      );
    }
    return true;
  });

  const filteredAccountGroups = clientAccountGroups.filter((group) => {
    if (searchValue.length > 0) {
      return (
        group.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
        filteredAccounts.findIndex(
          (acct) => acct.accountGroupEntry?.name === group.name
        ) > -1
      );
    }
    return true;
  });

  return (
    <>
      <Box sx={{ padding: 1 }}>
        <GoBackLinkButton
          link={`/my/client-configuration/${clientId}`}
          label='Return to Client Summary'
        />
      </Box>
      <Box sx={{ display: 'flex' }}>
        <ClientSummaryInfo
          displayName={
            clientUserProfile?.displayName ? clientUserProfile?.displayName : ''
          }
          netAssets={clientSummary?.summary?.netAssets}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 2,
        }}
      >
        <TextField
          type='search'
          variant='outlined'
          color='secondary'
          size='small'
          onChange={(event) => setSearchValue(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={(theme) => ({
            margin: 1,
            border: 1,
            borderColor: theme.extensions.grey[28],
            borderRadius: 1,
          })}
        />
        {clientUserProfile && (
          <CreateAccountGroupDialog
            clientAccountGroups={clientAccountGroups}
            clientId={clientId}
            displayName={clientUserProfile.displayName}
          />
        )}
      </Box>
      <AccountGroupAccordionList
        accounts={userAccessConfigurationData}
        accountGroups={clientAccountGroups}
        filteredAccounts={filteredAccounts}
        filteredAccountGroups={filteredAccountGroups}
        searchValue={searchValue}
      />
    </>
  );
};

export default AccountGroupEditView;
