import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { appConfig } from './appConfig';

let appInsights: ApplicationInsights;
let reactPlugin: ReactPlugin;

if (appConfig.appInsightsConnectionString) {
  reactPlugin = new ReactPlugin();
  appInsights = new ApplicationInsights({
    config: {
      connectionString: appConfig.appInsightsConnectionString,
      extensions: [reactPlugin],
      // enableDebug: true,
      // loggingLevelConsole: 2,
      // loggingLevelTelemetry: 2,
      // enableAjaxErrorStatusText: true,
      enableAutoRouteTracking: true,
      autoTrackPageVisitTime: true,
    },
  });
}

export { appInsights, reactPlugin };

export const initAppInsights = () => {
  // TODO: add custom configuration (add telemetry/dependency initializers, etc.)

  appInsights?.loadAppInsights();
};
