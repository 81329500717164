import { commitLocalUpdate, Environment } from 'react-relay';
import { RelayCachePersistence } from '../@types';

export const RelayClientPrefixId = 'client' as const;
// TODO: support InMemory cache lookups.  Not necessary atm, since this is primarily used to rehydrate or save cache to Local or Session Storage
export const getRelayCachePersistence = (cacheType: RelayCachePersistence) => {
  switch (cacheType) {
    case 'LocalStorage':
      return window.localStorage;
    case 'SessionStorage':
      return window.sessionStorage;
    default:
      throw new Error(
        'EdgeCo Relay libraries do not support the provided cache storage type or a type was not provided.'
      );
  }
};

export const getCachedValue = <TCachedItem = any>(
  cacheId: string,
  cachePersistence: RelayCachePersistence = 'LocalStorage',
  storageKey: string
): TCachedItem | null | undefined => {
  const storage = getRelayCachePersistence(cachePersistence);
  const cache = storage.getItem(storageKey);
  if (!cache) return undefined;

  const deserialized = JSON.parse(cache) ?? {};
  return deserialized[cacheId];
};

export const setCachedValue = <TCachedItem = any>(
  cacheId: string,
  value: TCachedItem,
  cachePersistence: RelayCachePersistence = 'LocalStorage',
  storageKey: string
): TCachedItem | null | undefined => {
  const storage = getRelayCachePersistence(cachePersistence);
  const cache = storage.getItem(storageKey);
  const deserialized = cache ? (JSON.parse(cache) as Record<string, any>) : {};
  deserialized[cacheId] = JSON.stringify(value);
  const newValue = JSON.stringify(deserialized);
  storage.setItem(storageKey, newValue);
  return deserialized[cacheId];
};

export const hydrateRelayStoreFromCache = (
  relayEnv: Environment,
  relayFieldKey: string = 'viewer',
  relayTypename: string = 'viewer',
  cachePersistence: RelayCachePersistence = 'LocalStorage',
  localStorageKey: string
) => {
  const persistedCache = getCachedValue(
    localStorageKey,
    cachePersistence,
    localStorageKey
  );
  if (!persistedCache) return;
  commitLocalUpdate(relayEnv, (store) => {
    const dataId = `${RelayClientPrefixId}:${relayTypename}`;
    const record = store.create(dataId, relayTypename);
    record.copyFieldsFrom(persistedCache);

    store.getRoot().setLinkedRecord(record, relayFieldKey);
  });
};

export const formatGuidString = (guidString: string) =>
  `${guidString.substring(0, 8)}-${guidString.substring(
    8,
    4
  )}-${guidString.substring(12, 4)}-${guidString.substring(
    16,
    4
  )}-${guidString.substring(20)}`;

export const convertGuidToRelayId = (typeName: string, guid: string) => {
  const guidString = guid.replace(/-/g, '');
  return btoa(`${typeName}\ng${guidString}`);
};

export const convertRelayIdToIdentifier = (
  typeName: string,
  relayString: string
) => {
  const guidString = atob(relayString)
    .replace(typeName, '')
    .trim()
    .substring(1);
  return guidString;
};
