import { AppBar, Box, Toolbar } from '@mui/material';
import UserAccountMenu from './UserAccountMenu';

type TopBarProps = {
  userDisplayName?: string | null;
};
export function TopBar({ userDisplayName }: TopBarProps) {
  return (
    <AppBar position='relative' sx={{ backgroundColor: 'transparent' }}>
      <Toolbar
        sx={(theme) => ({
          padding: theme.spacing(2, 2, 0, 2),
          alignItems: 'flex-end',
          width: 'auto',
          [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: theme.spacing(5, 2, 1, 2),
            margin: 'auto',
          },
        })}
      >
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={(theme) => ({
            display: 'none',
            [theme.breakpoints.up('md')]: {
              display: 'flex',
            },
          })}
        >
          <UserAccountMenu userDisplayName={userDisplayName} />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
