import { Box, Button, ButtonBase, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface PanelSortLabelProps {
  label: string;
  sortDirection: 'asc' | 'desc';
  onClick: () => void;
}

export const PanelSortLabel = ({
  label,
  sortDirection,
  onClick,
}: PanelSortLabelProps) => {
  return (
    <ButtonBase onClick={onClick} disableRipple>
      <Typography variant='h5' sx={{ fontWeight: 'bold', margin: 1 }}>
        {label}
      </Typography>
      {sortDirection === 'asc' ? (
        <ArrowDropUpIcon fontSize='large' />
      ) : (
        <ArrowDropDownIcon fontSize='large' />
      )}
    </ButtonBase>
  );
};

export default PanelSortLabel;
