import { FilterItem } from '@newedge/common';
import { DateTime } from 'luxon';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useQueryLoader } from 'react-relay';
import { useLocation, useParams } from 'react-router';
import {
  AccountActivityDataModelFilterInput,
  AccountActivityPreviewQueryReaderQuery,
  AccountActivityPreviewQueryReaderQuery$variables,
} from './__generated__/AccountActivityPreviewQueryReaderQuery.graphql';
import {
  AccountActivityPreviewQueryReader,
  AccountActivityPreviewQueryReaderQueryNode,
} from './AccountActivityPreviewQueryReader';
import { GlobalFilterContext } from '../../../components/context/GlobalFilterContext';
import { getDefaultFilterItems } from '@newedge/reports';

export const getAccountActivityFilterInput =
  (): AccountActivityDataModelFilterInput => {
    const now = DateTime.now();
    const startDate = now.startOf('month').minus({ months: 1 });

    return {
      and: [
        { dataDate: { gte: startDate.toFormat('yyyy-MM-dd') } },
        { dataDate: { lte: now.toFormat('yyyy-MM-dd') } },
      ],
    };
  };

const applySecondaryFilters = (
  variables: AccountActivityPreviewQueryReaderQuery$variables,
  filterValues: FilterItem[]
): AccountActivityPreviewQueryReaderQuery$variables => {
  const newVariables = Object.assign({}, variables);

  let input = variables.selectedAccounts;
  const accountIdFilters = filterValues.filter(
    (o) => o.predicateIdentifier === 'accountId'
  );
  if (accountIdFilters.length > 0) {
    const ids: any = new Set();
    accountIdFilters.forEach((o) => {
      if (o.searchValue instanceof Array) {
        o.searchValue.forEach((val) => {
          if (input.financialAccountIds.includes(Number(val))) {
            ids.add(val);
          }
        });
      } else {
        if (input.financialAccountIds.includes(Number(o.searchValue))) {
          ids.add(o.searchValue);
        }
      }
    });
    newVariables.selectedAccounts = {
      financialAccountIds: [...ids],
    };
  }

  const and: AccountActivityDataModelFilterInput[] = [];
  const or: AccountActivityDataModelFilterInput[] = [];

  const rangeFilter = filterValues.find((o) => o.filterType === 'range');
  if (rangeFilter && rangeFilter.predicateIdentifier) {
    and.push({
      [rangeFilter.predicateIdentifier]: {
        gte: rangeFilter.lowerBound,
        lte: rangeFilter.upperBound,
      },
    });
  }

  const dateFilter = filterValues.find((o) => o.filterType === 'date');
  if (dateFilter && dateFilter.date && dateFilter.predicateIdentifier) {
    and.push({
      [dateFilter.predicateIdentifier]: {
        gte: dateFilter.date.startDate?.toISO(),
        lte: dateFilter.date.endDate?.toISO(),
      },
    });
  }

  const remainingFilters = filterValues.filter(
    (o) =>
      o.predicateIdentifier &&
      o.predicateIdentifier !== 'accountId' &&
      o.predicateIdentifier !== 'amount' &&
      o.predicateIdentifier !== 'transactionDate'
  );

  if (remainingFilters.length > 0) {
    remainingFilters.forEach((o) => {
      if (o.predicateIdentifier) {
        switch (o.logic) {
          case 'or':
            or.push({
              [o.predicateIdentifier]: { eq: o.searchValue },
            });
            break;
          case 'and':
          case undefined:
          default:
            and.push({
              [o.predicateIdentifier]: { eq: o.searchValue },
            });
        }
      }
    });
  }

  let where: AccountActivityDataModelFilterInput | null = {
    or,
    and,
  };
  if (where.and?.length === 0) {
    delete where.and;
  }
  if (where.or?.length === 0) {
    delete where.or;
  }
  if (and.length > 0 || or.length > 0) {
    newVariables.filters = where;
  }

  return newVariables;
};

interface LocationState {
  secondaryFilters?: FilterItem[];
}

const getInitialFilterItems = (locationState: LocationState) => {
  if (!locationState) {
    return getDefaultFilterItems();
  }
  return locationState.secondaryFilters as FilterItem[];
};

export const AccountActivityPreviewLoader = () => {
  const [accountActivityQueryRef, loadAccountActivityQuery] =
    useQueryLoader<AccountActivityPreviewQueryReaderQuery>(
      AccountActivityPreviewQueryReaderQueryNode
    );

  const { selectedAccounts, allAccounts } = useContext(GlobalFilterContext);
  const { clientUserId } = useParams();

  const { state } = useLocation();

  const defaultFilterItems = getInitialFilterItems(state);

  const [filterValues, setFilterValues] =
    useState<FilterItem[]>(defaultFilterItems);

  const variables = useMemo(
    () => ({
      input: {
        clientUserIds: [clientUserId],
      },
      clientWhere: {
        userId: { eq: clientUserId },
      },
      selectedAccounts: {
        financialAccountIds: selectedAccounts,
      },
      allAccounts: {
        financialAccountIds: allAccounts,
      },
    }),
    [clientUserId, selectedAccounts, allAccounts]
  );

  useEffect(() => {
    loadAccountActivityQuery(applySecondaryFilters(variables, filterValues));
  }, [variables, filterValues, loadAccountActivityQuery]);

  if (accountActivityQueryRef) {
    return (
      <AccountActivityPreviewQueryReader
        queryRef={accountActivityQueryRef}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        hasInitialFilters={defaultFilterItems && defaultFilterItems.length > 0}
      />
    );
  }
  return <></>;
};
