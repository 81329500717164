import { ArrowRightAlt } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

interface GoBackLinkButtonProps {
  link: string;
  label: string;
  openInNewTab?: boolean;
}

export const GoForwardLinkButton = ({
  link,
  label,
  openInNewTab = false,
}: GoBackLinkButtonProps) => {
  const newTabProps = openInNewTab
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {};
  return (
    <Link to={link} {...newTabProps}>
      <Button
        variant='text'
        color='iron'
        sx={{ margin: 0, padding: 0 }}
      >
        {label}
        <ArrowRightAlt sx={{ marginLeft: 0.5 }} />
      </Button>
    </Link>
  );
};

export default GoForwardLinkButton;
