import { Box, TextField, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { useMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import CustomButton from '../../components/buttons/CustomButton';
import { ClientAccountNickNameConfigure_AccountNicknameConfigureMutation } from './__generated__/ClientAccountNickNameConfigure_AccountNicknameConfigureMutation.graphql';

interface ClientAccountNicknameConfigureProps {
  nickname: string;
  defaultNickname: string;
  clientId: string;
  financialAccountId: number;
  isUpdated: () => void;
}

const ClientAccountNicknameConfigureMutation = graphql`
  mutation ClientAccountNicknameConfigureMutation(
    $updateClientAccountNicknameInput: UpdateClientAccountNicknameInput!
  ) {
    UpdateClientAccountNickname(input: $updateClientAccountNicknameInput) {
      errors {
        code
        message
      }
      partitionKey
      request {
        correlationId
        status
      }
      userState {
        displayName
        accounts {
          accountNickname
          financialAccountId
        }
        accountGroups {
          accounts {
            financialAccountId
            id
          }
          id
          name
        }
      }
      viewer {
        displayName
        accounts {
          accountNickname
          financialAccountId
        }
        accountGroups {
          accounts {
            financialAccountId
            id
          }
          id
          name
        }
      }
    }
  }
`;

const ClientAccountNicknameConfigure = ({
  nickname = '',
  financialAccountId,
  defaultNickname = '',
  clientId = '',
}: ClientAccountNicknameConfigureProps) => {
  const [modifiedNickname, setModifiedNickname] = useState(nickname);
  const [buttonsState, setButtonsState] = useState({
    isUpdate: false,
    isReset: false,
  });

  const [commitAccountNicknameConfigure] =
    useMutation<ClientAccountNickNameConfigure_AccountNicknameConfigureMutation>(
      ClientAccountNicknameConfigureMutation
    );

  const handlerOnUpdate = (isReset = false) => {
    const newName = isReset ? defaultNickname : modifiedNickname;
    setModifiedNickname(newName);
    setButtonsState({
      isUpdate: false,
      isReset: false,
    });
    commitAccountNicknameConfigure({
      variables: {
        updateClientAccountNicknameInput: {
          accountNickname: newName,
          financialAccountId,
          clientId,
        },
      },
      updater: (store) => {
        const root = store.getRoot();
        const userProfileViewer = root.getLinkedRecord('userprofile_viewer');
        const clientUser = userProfileViewer?.getLinkedRecords(
          `clientUser(input:{"clientUserIds":["${clientId}"]})`
        );
        if (clientUser && clientUser.length > 0) {
          const accounts = clientUser[0].getLinkedRecords('accounts');
          accounts?.forEach((account) => {
            const currentFinancialAccountId =
              account.getValue('financialAccountId');
            if (currentFinancialAccountId === financialAccountId) {
              account.setValue(newName, 'accountNickname');
            }
          });
        }
      },
    });
  };

  const handlerEdit = () => {
    setButtonsState({
      isUpdate: true,
      isReset: modifiedNickname !== defaultNickname,
    });
  };

  const maxDisplayAccountNameLength = 72;
  const ellipsesCharacterLength = 3;

  const isValueLongerThanMax = (value: string | undefined) => 
    value && value.length > maxDisplayAccountNameLength;
  
  const truncateLongValue = (value: string) =>
    `${value.substring(
      0,
      maxDisplayAccountNameLength - ellipsesCharacterLength
    )}...`;

  const renderToolTipForLongValue = (
    nickname: string | undefined,
    accountName: string
  ) => {
    return !nickname ||
      nickname === accountName ||
      buttonsState.isUpdate ||
      nickname.length <= maxDisplayAccountNameLength ? (
      ''
    ) : (
      <Typography variant='caption'>{nickname}</Typography>
    );
  };

  return (
    <Box sx={{ display: 'flex', gap: '.5rem' }}>
      {!(buttonsState.isUpdate || buttonsState.isReset) ? (
        <Tooltip
          sx={(theme) => ({ ...theme.typography.caption })}
          title={renderToolTipForLongValue(nickname, defaultNickname)}
          disableInteractive
          disableFocusListener
          disableTouchListener
        >
          <Typography onClick={handlerEdit}>
            {isValueLongerThanMax(modifiedNickname)
              ? truncateLongValue(modifiedNickname)
              : modifiedNickname}
          </Typography>
        </Tooltip>
      ) : (
        <TextField
          type='search'
          variant='outlined'
          color='secondary'
          size='small'
          onChange={(e) => {
            setModifiedNickname(e.target.value);
          }}
          value={modifiedNickname}
          sx={{ width: '50%' }}
          inputProps={{
            maxLength: 72,
          }}
        />
      )}
      {buttonsState.isUpdate && (
        <CustomButton
          variant='md'
          buttonProps={{
            onClick: () => handlerOnUpdate(false),
            variant: 'contained',
            color: 'secondary',
          }}
        >
          Update
        </CustomButton>
      )}
      {buttonsState.isReset && (
        <CustomButton
          variant='md'
          buttonProps={{
            onClick: () => handlerOnUpdate(true),
            variant: 'contained',
            color: 'secondary',
          }}
        >
          Reset
        </CustomButton>
      )}
    </Box>
  );
};

export default ClientAccountNicknameConfigure;
